import { AutorDTO, makeAutorDTOFromAutor } from "./AutorDTO";
import Buch from "./Buch";

export type BuchDTO = {
    id: string;
    titel: string;
    subTitel: string;
    beschreibung: string;
    autoren: Array<AutorDTO>;
    genres: string[];
    datumVeroeffentlichung: string;
    bigThumbnailUrl: string;
    smallThumbnailUrl: string;
    preis: number;
    waehrung: string;
    isEbook: boolean;
    verlag: string;
    sprache: string;
    seitenanzahl: number;
    isbn: string;
    buecherDeLink: string;
    istGemerkt?: boolean;
}

export function makeBuchDTOOutOfBuch(buch: Buch): BuchDTO
    {
        let buchDTO: BuchDTO = {
            id: buch.id,
            titel: buch.titel,
            subTitel: buch.subTitel,
            beschreibung: buch.beschreibung,
            autoren: buch.autoren.map(a => makeAutorDTOFromAutor(a)),
            genres: buch.genres,
            datumVeroeffentlichung: buch.datumVeroeffentlichung,
            bigThumbnailUrl: buch.bigThumbnailUrl,
            smallThumbnailUrl: buch.smallThumbnailUrl,
            preis: buch.preis,
            waehrung: buch.waehrung,
            isEbook: buch.isEbook,
            verlag: buch.verlag,
            sprache: buch.sprache,
            seitenanzahl: buch.seitenanzahl,
            isbn: buch.isbn,
            buecherDeLink: buch.buecherDeLink,
            istGemerkt: buch.istGemerkt
        }
        return buchDTO;
    }