import ScrollPosition from '../Datenmodell/ScrollPosition';

const sessionStoreKey = "acoyo_buchmelder_scroll_position";

export default class ScrollPositionHandler {

    public speichereLetzteScrollPosition(scrollPosition: ScrollPosition): void {
        sessionStorage.setItem(sessionStoreKey, scrollPosition.value.toString());
    }

    public getLetzteScrollPosition(): ScrollPosition {
        let pos = sessionStorage.getItem(sessionStoreKey);
        let posNumber = 0;
        try {
            posNumber = pos ? parseInt(pos) : 0;
        }
        catch(ex) {
            posNumber = 0;
        }
        return new ScrollPosition(posNumber);
    }

    public resetLetzteScrollPosition(): void {
        sessionStorage.setItem(sessionStoreKey, "0");
    }
}