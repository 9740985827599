import ServiceClient from './ServiceClient';
import ServiceWorkerService from '../ServiceWorker/ServiceWorkerService';
import NutzerService from './NutzerService';
import BuchmelderDb from './BuchmelderDb';
import Subscription from '../Datenmodell/Subscription';
import Nutzer from '../Datenmodell/Nutzer';
import { ExtendedPushSubscription } from '../Datenmodell/ExtendedPushSubscription';


export default class SubscriptionService 
{
    private serviceClient: ServiceClient;
    private nutzerService: NutzerService;
    private db: BuchmelderDb;
    

    public constructor(serviceclient: ServiceClient, nService: NutzerService, db: BuchmelderDb) 
    {
        this.serviceClient = serviceclient;
        this.nutzerService = nService;
        this.db = db;        
    }

    public async getSubscription(): Promise<[Subscription, PushSubscription | null]> 
    {
        let subscriptionToReturn = new Subscription();
        let dbSubDTO = await this.db.getSubscription();
        subscriptionToReturn.isAutorenFilterActivated = dbSubDTO.isAutorenFilterActivated;

        if (!('serviceWorker' in navigator)) return [subscriptionToReturn, null];
        const registration = await navigator.serviceWorker.ready;
        if(registration && registration.pushManager)
        {
            let browserSub = await registration.pushManager.getSubscription();        
            subscriptionToReturn.isActivated = browserSub ? true : false;
            return [subscriptionToReturn, browserSub];
        }      
        
        return [subscriptionToReturn, null];
    }

    public async deactivatePush(): Promise<void> 
    {
        const registration = await navigator.serviceWorker.ready;
        let browserSub = await registration.pushManager.getSubscription();
        let success = await browserSub?.unsubscribe();
        await this.db.setzeAutorenFilterFuerSubscriptionToDeactivated();
        let nutzer = await this.nutzerService.getNutzer();
        if(!nutzer) nutzer = await this.nutzerService.registriereNutzer();

        if(nutzer)
        {
            await this.serviceClient.subscribeToBenachrichtigungen(nutzer.id, new ExtendedPushSubscription(null, "keine"));
        
            if(!success)
            {
                setTimeout(async () => {
                    await browserSub?.unsubscribe();
                }, 3000);
            }
        }        
    }

    public async activatePush(pushState: "autoren" | "alle"): Promise<void> {
        let sub = await this.getSubscription();
        let nutzer: Nutzer | null = await this.nutzerService.getNutzer();
        if(!nutzer) nutzer = await this.nutzerService.registriereNutzer();
        let subscription: PushSubscription | null;

        if(!sub[0].isActivated) {
            if (!('serviceWorker' in navigator)) return;

            const permission = await window.Notification.requestPermission();
            if (permission !== 'granted') 
            {
                throw new Error('Permission not granted for Notification');
            }
    
            const registration = await navigator.serviceWorker.ready;
            const applicationServerKey = ServiceWorkerService.getApplicationServerKey();
            const options = { applicationServerKey, userVisibleOnly: true }
            subscription = await registration.pushManager.subscribe(options);            
        } else {
            subscription = sub[1];
        }

        if(pushState == "alle") this.db.setzeAutorenFilterFuerSubscriptionToDeactivated();
        else this.db.setzeAutorenFilterFuerSubscriptionToActivated();

        await this.serviceClient.subscribeToBenachrichtigungen(nutzer.id, new ExtendedPushSubscription(subscription, pushState));
    }

    public async checkIfNutzerIsKnownInBackendIfSubscribed()
    {
        let sub = await this.getSubscription();
        if(sub && sub[0] && sub[0].isActivated)
        {
            let nutzer = await this.nutzerService.getNutzer();
            if(nutzer)
            {
                let existiertNutzerBereits = await this.serviceClient.existiertNutzerBereits(nutzer.id);
                if(!existiertNutzerBereits)
                {
                    await this.nutzerService.registriereNutzer();                
                }
            }            
        }
    }
}