import * as React from "react";
import SubscriptionService from "../Logik/SubscriptionService";

export interface IDialogLeererFilterAktivierteNachrichtenProps {    
    subscriptionService: SubscriptionService,
    hideDeactivated: () => void,
    hideActivated: () => void,
}

class DialogLeererFilterAktivierteNachrichten extends React.Component<IDialogLeererFilterAktivierteNachrichtenProps>
{
    constructor(props: IDialogLeererFilterAktivierteNachrichtenProps) 
    {
        super(props);        
    }

    async componentDidMount() {}

    componentWillUnmount() {}
  
    render() 
    {          return <>
                    <div className="benachrichtigungsDialog">
                    Dein Autor*innen-Filter ist leer und deine Push-Benachrichtigungen aktiviert. Möchstest du nun weiterhin über alle Neuerscheinungen benachrichtigt werden, oder sollen die Benachrichtigungen deaktiviert werden?
                    </div>
                    <div className="benachButtons">                            
                        <button type="button" className="bigAddButton benachButtonModal" onClick={() => this.deactivateBenachrichtungen()} onKeyUp={(event: any) => {if(event.keyCode === 13) this.deactivateBenachrichtungen()}}>Deaktivieren</button>
                        <button type="button" className="secondButton benachButtonModal" onClick={() => this.activateBenachrichtungen()} onKeyUp={(event: any) => {if(event.keyCode === 13) this.activateBenachrichtungen()}}>Aktiviert lassen</button>
                    </div>
                </>;
    }

    private async deactivateBenachrichtungen()
    {
        await this.props.subscriptionService.deactivatePush();
        this.props.hideDeactivated();
    }

    private async activateBenachrichtungen(){
        await this.props.subscriptionService.activatePush("alle");
        this.props.hideActivated();
    }
}
export default DialogLeererFilterAktivierteNachrichten;