import * as React from "react";

class Plus extends React.Component {
    render() {
        return (
            <svg version="1.1" id="Ebene_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" className="smallPlus" aria-hidden="true"
                width="50px" height="50px" viewBox="0 0 77.833 77.917" enableBackground="new 0 0 77.833 77.917">
                <line fill="none" stroke="var(--global-text-color)" strokeWidth="3" strokeLinecap="round" strokeMiterlimit="10" x1="38.973" y1="11.093" x2="38.973" y2="66.845"/>
                <line fill="none" stroke="var(--global-text-color)" strokeWidth="3" strokeLinecap="round" strokeMiterlimit="10" x1="11.096" y1="38.969" x2="66.85" y2="38.969"/>
            </svg>            
        )
    }
}

export default Plus;