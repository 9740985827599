import * as React from "react";
import "./detailsComponent.css";
import "./shared.css";
import Buch from "../Datenmodell/Buch";
import BuchService from "../Logik/BuecherService";
import moment from 'moment';
import BuchError from "../Datenmodell/BuchError";
import PulseLoader from "react-spinners/PulseLoader";
import UrlExtractor from "../Logik/UrlExtractor";
import MerklistenService from "../Logik/MerklistenService";
import BackArrow from "./backArrowSVGComponent";
import MerklistenOverlay from '../Komponenten/merklistenOverlayComponent';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeart, faCheck } from '@fortawesome/free-solid-svg-icons';
import { faHeart as farHeart } from '@fortawesome/free-regular-svg-icons';
import {Snackbar} from "@material-ui/core"
import { Link } from "react-router-dom";
export interface IDetailsProps {
    buchServive: BuchService,
    merklistenService: MerklistenService
}

export interface IDetailsState {
    buch: Buch | null,
    error: string | null,
    loading: boolean,
    istGemerkt: boolean,
    toggled: boolean
}

class Details extends React.Component<IDetailsProps, IDetailsState>
{
    constructor(props: IDetailsProps) {
        super(props);

        this.state = {
            buch: null,
            error: null,
            loading: true,
            istGemerkt: false,
            toggled: false
        };
    }

    async componentDidMount() {
        const buchId = UrlExtractor.extrahiereBuchIdAusUrl(window.location.href);
        if (buchId) {
            var b = await this.props.buchServive.getBuch(buchId);
            if (!b) this.setState({ error: "Das Buch konnte leider nicht gefunden werden.", loading: false });
            let istAufMerkliste = await this.props.merklistenService.istBuchAufMerkliste(buchId);
            if (b instanceof BuchError) this.setState({ error: "Das Buch konnte leider nicht geladen werden. Prüfe bitte deine Internetverbindung.", loading: false });
            else this.setState({ buch: b, istGemerkt: istAufMerkliste, loading: false });
            //else this.setState({ buch: b, loading: false });
        }
        else {
            this.setState({ error: "Das Buch konnte leider nicht gefunden werden.", loading: false });
        }
    }

    componentWillUnmount() {
    }

    render() {       
        const errorMessage = this.state.error ? <div id="detailsError">{this.state.error}</div> : null;
        let date = moment(this.state.buch?.datumVeroeffentlichung, "YYYY-MM-DD").format("DD.MM.yyyy");
        const thumbnailUrl = this.state.buch?.bigThumbnailUrl ? this.state.buch.bigThumbnailUrl :
            (this.state.buch?.smallThumbnailUrl ? this.state.buch.smallThumbnailUrl : "");
        return <div className="detailsWrapper">
            <div className="detailsHeader">
                <button type="button" id="backButtonDetails" className="backButton" aria-label="Zurück navigieren" 
                    onClick={() => { this.goBack() }}
                    onKeyUp={(event: any) => {if(event.keyCode === 13) this.goBack() }}>
                        {/* <img src="ArrowBackSvg.svg" width="25px" alt="Zurück" /> */}
                        <BackArrow/>
                </button>
                <h1>Details: {this.state.buch?.titel}</h1>
            </div>
            {errorMessage}
            {this.state.loading && <div className="loadingWrapper"><PulseLoader color={"var(--global-white)"} loading={this.state.loading} size={30} speedMultiplier={0.6} margin={20}/></div>}
            {!this.state.loading && this.state.buch &&
                <div className="buchDetails" id="buchDetails">
                    <button type="button" id="heartButton"
                        onClick={() => {this.toggleGemerktesBuch()}}
                        onKeyUp={(event: any) => {if(event.keyCode === 13) this.toggleGemerktesBuch()}}>
                        {this.state.istGemerkt 
                            ? <FontAwesomeIcon icon={faHeart} size="2x" className="heart" id="fullHeart"/>
                            : <FontAwesomeIcon icon={farHeart} size="2x" className="heart" id="solidHeart"/>
                        }
                    </button>
                    <div className="detailsImageWrapper">
                        <img src={thumbnailUrl} />                        
                    </div>

                    <div className="autorDetail" id="autorDetail">{this.state.buch!.autoren
                        .map(a => <span key={a.name}><Link to={`/AlleBuecher?autor_in=${a.name}`} className="autorenBuecherLink">                        
                                        {a.name}{this.state.buch!.autoren.indexOf(a) != this.state.buch!.autoren.length -1 ? "," : ""}
                                    </Link>&nbsp;</span>)}
                    </div>
                    <div className="titelDetail" id="titelDetail">{this.state.buch?.titel}</div>
                    <div className="subTitelDetail" id="subTitelDetail">{this.state.buch?.subTitel}</div>

                    <div className="additionalInfo">
                        <div className="infoDetail"><div className="infoLabel">Genre:</div>{this.state.buch?.genres && this.state.buch?.genres.length > 0 ? this.state.buch?.genres.join(", ") : "-"}</div>
                        <div className="infoDetail"><div className="infoLabel">Seitenanzahl:</div>{this.state.buch?.seitenanzahl ?? "-"}</div>
                        <div className="infoDetail"><div className="infoLabel">Preis {this.state.buch.isEbook ? "(eBook)" : ""}:</div>{this.state.buch?.preis ?? "-"} {this.state.buch?.waehrung}</div>
                        <div className="infoDetail"><div className="infoLabel">Verlag:</div>{this.state.buch?.verlag ?? "-"}</div>
                        <div className="infoDetail"><div className="infoLabel">ISBN:</div>{this.state.buch.isbn ?? "-"}</div>
                        <div className="infoDetail"><div className="infoLabel">Veröffentlicht am:</div>{date}</div>
                    </div>

                    <div className="beschreibung" id="beschreibung">
                        <h3>Beschreibung:</h3>
                        {this.state.buch?.beschreibung}
                    </div>

                    { (this.state.buch?.buecherDeLink) &&
                        <div className="kaufen">
                            <h3>Jetzt kaufen*:</h3>                            
                            {this.state.buch?.buecherDeLink && <a href={this.state.buch?.buecherDeLink} target="_blank" rel="noopener noreferrer" id="buecherDeLink">Bücher.de</a>}
                            <p className="fußnote">
                                * Nach Klick auf einen Link erhalten wir für erfolgreiche Kaufabschlüsse eine kleine Provision. Der Endpreis ändert sich dadurch nicht.                                
                            </p>
                        </div>
                    }
                </div>
            }
            <Snackbar open={this.state.toggled} 
                    id="toggleSuccessBar"
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                    autoHideDuration={3000}
                    onClose={() => this.handleSnackbarClose()}
                    >
                        <div className="toggledSnackbarWrapper">                                    
                            <div className="toggledSnackbarContent">
                                <FontAwesomeIcon icon={faCheck} size="1x" className="checkIcon"/>
                                {this.state.istGemerkt 
                                            ? <span>Buch gemerkt.</span>
                                            : <span>Buch entfernt.</span>
                                }                                   
                            </div>                                    
                        </div>                           
            </Snackbar>
            <MerklistenOverlay/>
        </div>;
    }


    private handleSnackbarClose(): void {
        this.setState({toggled : false});
    }


    private  toggleGemerktesBuch() 
    {
        if(this.state.buch) 
        {
            if(this.state.istGemerkt) 
            {
                this.props.merklistenService.entmerkeBuch(this.state.buch.id);
                this.setState({istGemerkt: false, toggled : true});
            }
            else 
            {
                this.props.merklistenService.merkeBuch(this.state.buch.id);
                this.setState({istGemerkt: true, toggled : true});
            }
        }
    }

    private goBack(): void {
        window.history.back();
    }
}
export default Details;