export class ExtendedPushSubscription {
    public subscription?: PushSubscription | null;
    public pushState: 0 | 1 | 2;

    public constructor(sub: PushSubscription | null, pushState: "keine" | "autoren" | "alle") {
        this.subscription = sub;
        switch (pushState) {
            case "keine" : this.pushState = 0; break;
            case "autoren" : this.pushState = 1; break;
            case "alle" : this.pushState = 2; break;
        } 
    }
}