import * as React from "react";
import "./autorenFilterComponent.css";
import "./shared.css";
import AutorenService from "../Logik/AutorenService";
import Autor from "../Datenmodell/Autor";
import SkyLight from 'react-skylight';
import DeletePopupContentComponent from './autorDeleteComponent';
import SubscriptionService from "../Logik/SubscriptionService";
import AutorAutocompleteSelect from "./autorAutocompleteSelect";
import VorschlagsAutorenService from "../Logik/VorschlagsAutorenService";
import PulseLoader from "react-spinners/PulseLoader";
import Plus from "./plusSVGComponent";
import { Link } from "react-router-dom";
import {dialogStyles, closeButtonStyles} from './dialogStyles';
import DialogLeererFilterAktivierteNachrichten from "./DialogLeererFilterAktivierteNachrichten";

export interface IAutorenFilterProps 
{    
    autorenService: AutorenService,
    subscriptionService: SubscriptionService,
    somethingChanged: () => void,
    vorschlagsAutorService: VorschlagsAutorenService,
    parentLoading: boolean
}

export interface IAutorenFilterState 
{
    autorenFilter: Array<Autor>;
    currentAutorValue: Autor | null;
    autorToDelete: Autor | null;
    activeAutorForFilter: Autor | null;
    loading: boolean;
 }


class AutorenFilter extends React.Component<IAutorenFilterProps, IAutorenFilterState> 
{
    private simpleDialog;
    private simpleDialogDelete;
    private inputElement;
    private benachAktivierenDialog;
    private benachEinschraenkenDialog;
    private benachWeiterhinDialog;

    constructor(props: IAutorenFilterProps) 
    {
        super(props);
        this.inputElement = React.createRef();
        this.state = {
            autorenFilter: new Array<Autor>(),
            currentAutorValue: null,
            autorToDelete: null,
            activeAutorForFilter: null,
            loading: true,
        };
    }

    async componentDidMount() 
    {      
        await this.loadAutorenFilter();
    }

    componentDidUpdate() {}

    componentWillUnmount() {}
  
    render() 
    { 
        const noFilterPart = <div className="noFilter">
                                <span className="noFilterText">Du hast noch keine*n Lieblingsautor*in hinterlegt.</span>
                                <button type="button" className="bigAddButton" id="openAddAutorInputButton" onClick={() => this.openAddAutorInput()} onKeyUp={(event: any) => {if(event.keyCode === 13) this.openAddAutorInput()}}><img src="PlusWeiß.svg" className="buttonIcon" alt=""/>Autor*in hinzufügen</button>
                            </div>;
        
        const autorenCards = this.state.autorenFilter.map((autor: Autor) => 
                            <div className={this.state.activeAutorForFilter?.name == autor.name ? "autorCard activeAutor" : "autorCard"} key={autor.name} data-autor={autor.name}>
                                <Link aria-label="Alle Bücher dieses Autors / dieser Autorin anzeigen" to={`/AlleBuecher?autor_in=${autor.name}`} className="autorCardbutton">                        
                                    {autor.name}
                                </Link>
                                <button type="button" aria-label="Autor oder Autorin löschen" className="littleClose" onClick={() => this.openDeleteAutorPopup(autor)} onKeyUp={(event: any) => {if(event.keyCode === 13) this.openDeleteAutorPopup(autor)}}><img src="XSvg.svg" width="28px" height="28px" alt=""/></button>
                            </div>);
       
        const filterPart = <div className="autorenWrapper">
                                <button type="button" aria-label="Autor oder Autorin hinzufügen" id="autorAddButton" className="smallAddButton" onClick={() => this.openAddAutorInput()} onKeyUp={(event: any) => {if(event.keyCode === 13) this.openAddAutorInput()}}>
                                    {/* <img src="PlusSvg.svg" width="50px" height="50px" className="smallPlus" alt=""/> */}
                                    <Plus/>
                                </button>
                                {autorenCards}
                            </div>;

        const autorenFilter = this.state.autorenFilter && this.state.autorenFilter.length > 0 ? filterPart : noFilterPart;

        const loader = <div className="loadingAutorWrapper"><PulseLoader color={"var(--global-white)"} loading={this.state.loading || this.props.parentLoading} size={30} speedMultiplier={0.6} margin={20}/></div>;

        return <div className="nachAutorenWrapper">
                
                <div className={"autorenFilterWrapper"}>                    
                    {!this.state.loading && autorenFilter} 
                    {(this.state.loading || this.props.parentLoading) && loader}                   
                </div>
                <div className="popupWrapper">
                        <SkyLight ref={ref => this.simpleDialog = ref} dialogStyles={dialogStyles} closeButtonStyle={closeButtonStyles} autoFocus={false} afterClose={() => {this.setState({currentAutorValue: new Autor("")})}}>
                            <div className="autorField">
                                <label className="autorenLabel">Autor*in:</label>
                                <AutorAutocompleteSelect hasChanged={(autor: Autor) => this.handleChangeCurrentAutor(autor)} vorschlagsAutorService={this.props.vorschlagsAutorService} ref={this.inputElement}/>
                            </div>
                            <button type="submit" className="bigAddButton addButtonModal" id="autorHinzufuegenButton" onClick={() => this.addAutor()} onKeyUp={(event: any) => {if(event.keyCode === 13) this.addAutor()}}>Hinzufügen</button>
                        </SkyLight>
                        <SkyLight ref={ref => this.simpleDialogDelete = ref} dialogStyles={dialogStyles} closeButtonStyle={closeButtonStyles} autoFocus={false} afterClose={() => {this.setState({autorToDelete: null})}}>
                            <DeletePopupContentComponent autor={this.state.autorToDelete} delete={() => this.deleteAutor()} abortDelete={() => this.abortDeleteAutor()}/>               
                        </SkyLight>

                        <SkyLight ref={ref => this.benachAktivierenDialog = ref} dialogStyles={dialogStyles} closeButtonStyle={closeButtonStyles} autoFocus={false}>
                            <div className="benachrichtigungsDialog">
                                Möchtest du 
                                Push-Benachrichtigungen 
                                erhalten, wenn dein*e
                                Lieblings-Autor*in 
                                ein Buch veröffentlicht?
                                (Du kannst dies jederzeit 
                                in den Einstellungen ändern.)
                            </div>
                            <div className="benachButtons">
                                <button type="button" className="bigAddButton benachButtonModal" onClick={() => this.benachrichtungenAktivieren()} onKeyUp={(event: any) => {if(event.keyCode === 13) this.benachrichtungenAktivieren()}}>Aktivieren</button>
                                <button type="button" className="secondButton benachButtonModal" onClick={() => this.benachAktivierenDialog.hide()} onKeyUp={(event: any) => {if(event.keyCode === 13) this.benachAktivierenDialog.hide()}}>Nein danke</button>
                            </div>
                        </SkyLight>

                        <SkyLight ref={ref => this.benachEinschraenkenDialog = ref} dialogStyles={dialogStyles} closeButtonStyle={closeButtonStyles} autoFocus={false}>
                            <div className="benachrichtigungsDialog">
                            Du hast eine*n Autor*in zum Filter hinzugefügt. Möchstest du deine Pushbenachrichtigungen einschränken, um nur über Neuerscheinungen dieser Autor*innen benachrichtigt zu werden?
                            </div>
                            <div className="benachButtons">                            
                                <button type="button" className="bigAddButton benachButtonModal" onClick={() => this.einschraenken()} onKeyUp={(event: any) => {if(event.keyCode === 13) this.einschraenken()}}>Einschränken</button>
                                <button type="button" className="secondButton benachButtonModal" onClick={() => this.benachEinschraenkenDialog.hide()} onKeyUp={(event: any) => {if(event.keyCode === 13) this.benachEinschraenkenDialog.hide()}}>So lassen</button>
                            </div>
                        </SkyLight>

                        <SkyLight ref={ref => this.benachWeiterhinDialog = ref} dialogStyles={dialogStyles} closeButtonStyle={closeButtonStyles} autoFocus={false} beforeClose={async () => {await this.deactivateBenachrichtungen()}}>
                            <DialogLeererFilterAktivierteNachrichten hideDeactivated={() => this.hide()} hideActivated={() => this.hide()} subscriptionService={this.props.subscriptionService}/>
                        </SkyLight>
                    </div>
            </div> 
    }

    private handleChangeCurrentAutor(autor: Autor): void 
    {
        if(autor) this.setState({currentAutorValue: autor});
        else this.setState({currentAutorValue: null});
    }

    private async deactivateBenachrichtungen()
    {
        await this.props.subscriptionService.deactivatePush();
        this.hide();
    }

    private openAddAutorInput(): void 
    {        
        this.simpleDialog.show();        
        this.inputElement.current.resetValue();    
    }

    private openDeleteAutorPopup(autor: Autor): void 
    {
        this.setState({autorToDelete: autor});
        this.simpleDialogDelete.show();
    }

    private async addAutor(): Promise<void> 
    {
        if(this.state.currentAutorValue && this.state.currentAutorValue.name) 
        {
            await this.props.autorenService.speicherAutor(this.state.currentAutorValue);
            await this.loadAutorenFilter();
            this.setState({currentAutorValue: new Autor("")});
            this.simpleDialog.hide();

            if(this.state.autorenFilter.length == 1) 
            {
                let sub = await this.props.subscriptionService.getSubscription();
                if(sub[0].isActivated)
                {
                    this.benachEinschraenkenDialog.show();
                }
                else 
                {
                    this.benachAktivierenDialog.show();
                }
            }
            
            this.props.somethingChanged();            
        }        
    }

    private async loadAutorenFilter(): Promise<void> 
    {
        let filter = await this.props.autorenService.getAutorenFilter();
        filter.sort((a, b) => (Autor.compareAutoren(a, b)));
        this.setState({autorenFilter: filter, loading: false});
    }   

    private async deleteAutor() 
    {
        if(this.state.autorToDelete && this.state.autorToDelete.name)
         {
            await this.props.autorenService.loescheAutor(this.state.autorToDelete);            
            this.setState({autorToDelete: null});
            await this.loadAutorenFilter();
            this.simpleDialogDelete.hide();
            
            if(this.state.autorenFilter.length == 0) 
            {
                let sub = await this.props.subscriptionService.getSubscription();
                if(sub[0].isActivated && sub[0].isAutorenFilterActivated)
                {
                    this.benachWeiterhinDialog.show();
                }
            }

            this.props.somethingChanged();
        }        
    }

    private abortDeleteAutor() 
    {
        this.setState({autorToDelete: null});
        this.simpleDialogDelete.hide();
    }

    private hide(){
        this.benachWeiterhinDialog.hide();
    }

    private async einschraenken()
    {
        await this.props.subscriptionService.activatePush("autoren");
        this.benachEinschraenkenDialog.hide();
    }

    private async benachrichtungenAktivieren()
    {
        await this.props.subscriptionService.activatePush("autoren");
        this.benachAktivierenDialog.hide();
    }

}
export default AutorenFilter;