import BuchmelderDb from './BuchmelderDb';
import ServiceClient from './ServiceClient';

export default class NotSendRequestService 
{
    private serviceClient: ServiceClient;
    private db: BuchmelderDb;

    public constructor(externalServiceClient: ServiceClient, buecherDb: BuchmelderDb) 
    {
        this.serviceClient = externalServiceClient;
        this.db = buecherDb;
    }

    public async sendAllOpenRequests(): Promise<void>
    {
        let requests = await this.db.holeRequests();
        for (const r of requests) 
        {
            try
            {
                await this.serviceClient.sendSavedRequest(r);
                this.db.loescheRequest(r);
            }
            catch(ex)
            {
                console.error("Fehler beim Versenden offener Requests.", r);
            }
        }
    }
}