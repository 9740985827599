import Autor from '../Datenmodell/Autor';
import BuchmelderDb from './BuchmelderDb';
import ServiceClient from './ServiceClient';
import {AutorDTO} from '../Datenmodell/AutorDTO';
import Nutzer from '../Datenmodell/Nutzer';
import NutzerService from './NutzerService';

export default class AutorenService {

    private serviceClient: ServiceClient;
    private db: BuchmelderDb;
    private nutzerService: NutzerService;

    public constructor(externalServiceClient: ServiceClient, buecherDb: BuchmelderDb, nService: NutzerService) {
        this.serviceClient = externalServiceClient;
        this.db = buecherDb;
        this.nutzerService = nService;
    }

    public async getAutorenFilter(): Promise<Array<Autor>> {
        let autorenVonDb: Array<AutorDTO> = await this.db.autorenFilter();
        if(!autorenVonDb || autorenVonDb.length == 0)
        {
            try {
                let nutzer: Nutzer | null = await this.nutzerService.getNutzer();
                if(nutzer) 
                {
                    let autorenVonService = await this.serviceClient.holeAutorenFilter(nutzer.id);
                    await this.db.speichereAutoren(autorenVonService);
                    autorenVonDb = await this.db.autorenFilter();
                }
                else
                {
                    return new Array<Autor>();
                }
                
            } catch(ex) {
                console.error("Fehler beim Abrufen des Nutzers.");
            }
        }
        return autorenVonDb.map(adto => new Autor(adto.value));
    }

    public async speicherAutor(autor: Autor): Promise<void> {
        await this.db.speichereAutor({value: autor.name} as AutorDTO);
        await this.sendAutorenFilterToServer();       
    }

    public async loescheAutor(autor: Autor): Promise<void> {
        await this.db.loescheAutor({value: autor.name} as AutorDTO);
        await this.sendAutorenFilterToServer();
    }

    private async sendAutorenFilterToServer() {
        try {
            let nutzer: Nutzer | null = await this.nutzerService.getNutzer();
            if(!nutzer) nutzer = await this.nutzerService.registriereNutzer();
            if (nutzer) {
                const neuerFilter = await this.db.autorenFilter();
                await this.serviceClient.sendeNeuenAutorenFilter(nutzer.id, neuerFilter.map(a => a.value));
            }

        } catch (ex) {
            console.error("Fehler beim Abrufen des Nutzers.");
        }
    }
}