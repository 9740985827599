import * as React from "react";
import "./configComponent.css";
import "./shared.css";
import AutorenService from "../Logik/AutorenService";
import SubscriptionService from "../Logik/SubscriptionService";
import Autor from "../Datenmodell/Autor";
import { Link } from "react-router-dom";
import SkyLight from 'react-skylight';
import DeletePopupContentComponent from './autorDeleteComponent';
import AutorAutocompleteSelect from "./autorAutocompleteSelect";
import VorschlagsAutorenService from "../Logik/VorschlagsAutorenService";
import PulseLoader from "react-spinners/PulseLoader";
import BackArrow from "./backArrowSVGComponent";
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import ThemeService from "../Logik/ThemeService";
import { ColorTheme } from "../Datenmodell/ColorTheme";
import Subscription from '../Datenmodell/Subscription';
import DialogLeererFilterAktivierteNachrichten from "./DialogLeererFilterAktivierteNachrichten";
import {dialogStyles, closeButtonStyles} from './dialogStyles';

export interface IConfigProps 
{    
    autorenService: AutorenService;
    vorschlagsAutorService: VorschlagsAutorenService;
    subscriptionService: SubscriptionService;
    themeService: ThemeService;
}

export interface IConfigState 
{
   autoren: Array<Autor>;
   currentAutorValue: Autor | null;
   autorToDelete: Autor | null;
   loading: boolean;
   selectedColor: "System" | "Dunkel" | "Hell";
   selectedPushState: "keine" | "autoren" | "alle";
}

class Config extends React.Component<IConfigProps, IConfigState>
{
    private simpleDialogDelete;
    private benachWeiterhinDialog;
    private inputElement;

    constructor(props: IConfigProps) 
    {
        super(props);
        this.inputElement = React.createRef();
        this.state = 
        {
            autoren: new Array<Autor>(),
            currentAutorValue: null,
            autorToDelete: null,
            loading: true,
            selectedColor: "System",
            selectedPushState: "keine"
        };
        
    }

    async componentDidMount() 
    {
       this.scrollToTop();
       let autoren = await this.props.autorenService.getAutorenFilter();
       let sub = await this.props.subscriptionService.getSubscription();
       autoren.sort((a, b) => (Autor.compareAutoren(a, b)));
       let theme = await this.props.themeService.getTheme();
       this.setState({
           autoren: autoren,
           selectedColor: ColorTheme[theme],
           loading: false,
           selectedPushState: this.getPushState(sub[0])
        });
    }

    private getPushState(sub: Subscription) : "keine" | "autoren" | "alle"
    {
        if(sub.isActivated && sub.isAutorenFilterActivated) {
            return "autoren";
        }
        if(sub.isActivated && !sub.isAutorenFilterActivated) {
            return "alle"
        }
        if(!sub.isActivated && !sub.isAutorenFilterActivated) {
            return "keine"
        }
        return "keine";
    }

    render() 
    {          
        const autorenCards = this.state.autoren.map((autor: Autor) => 
                            <div className="autorCardConfig" key={autor.name}>
                                <button type="button" className="autorCardConfigbutton">
                                    {autor.name}                                    
                                </button>
                                <button type="button" className="littleClose" ><img src="XSvg.svg" width="28px" height="28px" alt="Löschen"
                                        onClick={() => this.openDeleteAutorPopup(autor)} onKeyUp={(event: any) => {if(event.keyCode === 13) this.openDeleteAutorPopup(autor)}}/></button>
                            </div>);

        return <div className="configWrapper">
            <div className="detailsHeader">
                <button type="button" className="backButton" id="backButton" onClick={() => { this.goBack() }}><BackArrow/></button>
                <h1>Einstellungen</h1>
            </div>
            <div className="linkPart">
                <Link to="/UeberDieApp" className="configLink">Über die App</Link>
                <Link to="/UeberAcoyo" className="configLink">Über Acoyo</Link>
                <Link to="/Impressum" className="configLink">Impressum</Link>
            </div>
            <div className="pushArea">
                <div className="configLoadingWrapper"><PulseLoader color={"#DBDBDB"} loading={this.state.loading} size={20} speedMultiplier={0.6} margin={20}/></div>
                <h2>Push Benachrichtigungen</h2>
                <RadioGroup row aria-label="Push Benachrichtigungen" name="notifications" value={this.state.selectedPushState} className="radioGroup">
                    <FormControlLabel
                    className="radioLabel"
                    value="alle"
                    control={
                        <Radio color="primary" checked={this.state.selectedPushState === 'alle'} onChange={(e) => this.handlePushChange(e)} disabled={this.state.loading}/>
                    }
                    label="Für alle Neuerscheinungen"
                    labelPlacement="bottom"
                    />
                    <FormControlLabel
                    className="radioLabel"
                    value="autoren"
                    disabled={this.state.autoren.length == 0}
                    control={<Radio color="primary" checked={this.state.selectedPushState === 'autoren'} onChange={(e) => this.handlePushChange(e)} disabled={this.state.loading} />}
                    label="Für Neuerscheinungen deiner Lieblings&shy;autor*innen"
                    labelPlacement="bottom"
                    />
                    <FormControlLabel
                    className="radioLabel"
                    value="keine"
                    control={<Radio color="primary" checked={this.state.selectedPushState === 'keine'} onChange={(e) => this.handlePushChange(e)} disabled={this.state.loading} />}
                    label="Keine Benachrichtigungen"
                    labelPlacement="bottom"
                    />
                </RadioGroup>              
            </div>
            <div className="autorenArea">
                <h2>Autor*innenfilter</h2>
                <p className="configAreaMarginBottom"> Füge Autor*innen (Vorname und Nachname) 
                    hinzu, um in der Hauptansicht unter &quot;Bücher meiner Autor*innen&quot; die Neuerscheinungen gefiltert nach diesen Autor*innen zu sehen. (Oder entferne sie über das &quot;x&quot;.)
                    <br/><br/>Dieser Filter wird auch für die Pushbenachrichtungen verwendet, sofern du dies oben aktivierst.
                </p>
                <div className="inputArea" id="autorenInput">
                    <AutorAutocompleteSelect hasChanged={(autor: Autor) => this.handleChangeCurrentAutor(autor)} vorschlagsAutorService={this.props.vorschlagsAutorService} ref={this.inputElement}/>
                    <button type="button" className="bigAddButton" id="autorenConfigAddButton" onClick={() => this.addAutor()}>Hinzufügen</button>
                </div>
                <div className="autorenWrapperConfig">
                    <PulseLoader color={"#DBDBDB"} loading={this.state.loading} size={20} speedMultiplier={0.6} margin={20}/>
                    {autorenCards}
                </div>
            </div>
            <div className="themeArea">
                <h2>Farbschema der App</h2>
                <p className="configAreaMarginBottom">Entscheide, ob die Anwendung in hellen oder dunklen Farben 
                    erscheinen soll, oder ob die Einstellungen vom Betriebssystem
                    übernommen werden sollen.</p>
                <RadioGroup row aria-label="Farbschema" name="themes" defaultValue="hell" className="radioGroup2">
                    <FormControlLabel
                    className="radioLabel"
                    value="Hell"
                    control={
                        <Radio color="primary" checked={this.state.selectedColor === 'Hell'} onChange={(e) => this.handleThemeChange(e)} disabled={this.state.loading}/>
                    }
                    label="Hell"
                    labelPlacement="bottom"
                    />
                    <FormControlLabel
                    className="radioLabel"
                    value="Dunkel"
                    control={<Radio color="primary" checked={this.state.selectedColor === 'Dunkel'} onChange={(e) => this.handleThemeChange(e)} disabled={this.state.loading} />}
                    label="Dunkel"
                    labelPlacement="bottom"
                    />
                    <FormControlLabel
                    className="radioLabel"
                    value="System"
                    control={<Radio color="primary" checked={this.state.selectedColor === 'System'} onChange={(e) => this.handleThemeChange(e)} disabled={this.state.loading} />}
                    label="System"
                    labelPlacement="bottom"
                    />
                </RadioGroup>
            </div>
            <div className="popupWrapper">
                <SkyLight ref={ref => this.simpleDialogDelete = ref} dialogStyles={dialogStyles} closeButtonStyle={closeButtonStyles} autoFocus={false} afterClose={() => {this.setState({autorToDelete: null})}}>
                    <DeletePopupContentComponent autor={this.state.autorToDelete} delete={() => this.deleteAutor()} abortDelete={() => this.abortDeleteAutor()}/>               
                </SkyLight>
                <SkyLight ref={ref => this.benachWeiterhinDialog = ref} dialogStyles={dialogStyles} closeButtonStyle={closeButtonStyles} autoFocus={false} beforeClose={async () => {await this.deactivateBenachrichtungen()}}>
                    <DialogLeererFilterAktivierteNachrichten hideDeactivated={() => {this.hide(); this.setState({selectedPushState: "keine"})}} hideActivated={() => {this.hide(); this.setState({selectedPushState: "alle"})}} subscriptionService={this.props.subscriptionService}/>
                </SkyLight>
            </div>         
        </div>;
    }

    private scrollToTop(): void
    {
        window.scrollTo(0, 0);
    }

    private goBack(): void 
    {
        window.history.back();
    }

    private async deactivateBenachrichtungen()
    {
        await this.props.subscriptionService.deactivatePush();
        this.hide(); 
        this.setState({selectedPushState: "keine"});
    }

    private async handleThemeChange(event: any)
    {
        this.setState({selectedColor: event.target.value});
        await this.props.themeService.setTheme(ColorTheme[event.target.value]);
    }

    private handlePushChange(event: any): void
    {        
        let newValue = event.target.value;
        this.setState({selectedPushState: newValue});
        if(newValue == "keine") {
            this.props.subscriptionService.deactivatePush();
        }
        else {
            this.props.subscriptionService.activatePush(newValue);
        }
    }

    private handleChangeCurrentAutor(autor: Autor): void 
    {
        if(autor) this.setState({currentAutorValue: autor});
        else this.setState({currentAutorValue: null});
    }

    private async addAutor(): Promise<void> 
    {
        if(this.state.currentAutorValue && this.state.currentAutorValue.name) 
        {
            await this.props.autorenService.speicherAutor(this.state.currentAutorValue);
            await this.loadAutorenFitler();
            this.setState({currentAutorValue: new Autor("")});
            this.inputElement.current.resetValue();
        }        
    }

    private async loadAutorenFitler(): Promise<void> 
    {
        let filter = await this.props.autorenService.getAutorenFilter();
        filter.sort((a, b) => (Autor.compareAutoren(a, b)));
        this.setState({autoren: filter});
    }

    private openDeleteAutorPopup(autor: Autor): void 
    {
        this.setState({autorToDelete: autor});
        this.simpleDialogDelete.show();
    }

    private async deleteAutor() 
    { 
        if(this.state.autorToDelete && this.state.autorToDelete.name)
         {
            await this.props.autorenService.loescheAutor(this.state.autorToDelete);            
            this.setState({autorToDelete: null});
            await this.loadAutorenFitler();
            this.simpleDialogDelete.hide();
            
            if(this.state.autoren.length == 0) 
            {
                let sub = await this.props.subscriptionService.getSubscription();
                if(sub[0].isActivated && sub[0].isAutorenFilterActivated)
                {
                    this.benachWeiterhinDialog.show();
                }
            }
        } 
    }

    private hide(){
        this.benachWeiterhinDialog.hide();
    }

    private abortDeleteAutor() 
    {
        this.setState({autorToDelete: null});
        this.simpleDialogDelete.hide();
    }
}
export default Config;