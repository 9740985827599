import * as React from "react";

class Schriftzug extends React.Component {
    render() {
        return (
            <svg
                width="100%"
                viewBox="0 0 493 96"
                version="1.1"
                id="svg19"                
                xmlns="http://www.w3.org/2000/svg">
                <defs
                    id="defs23" />                
                <g
                    id="Gruppe_1"
                    data-name="Gruppe 1"
                    transform="translate(1.5 -2.532)">
                    <g
                    aria-label="Acoyo Software GmbH Agile coding for you."
                    transform="translate(-1.5 62.532)"
                    id="Acoyo_Software_GmbH_Agile_coding_for_you.">
                    <path
                        d="m 3.78,0 22.75,-57.75 h 4.935 L 54.18,0 H 48.44 L 41.405,-18.41 H 16.59 L 9.485,0 Z m 14.595,-23.1 h 21.21 L 28.98,-50.75 Z"
                        stroke="none"
                        fill="var(--global-text-color)"
                        id="path2856" />
                    <path
                        d="m 82.179919,1.12 q -4.025,0 -7.56,-1.4 -3.535,-1.435 -6.195,-4.025 -2.66,-2.59 -4.165,-6.055 -1.505,-3.465 -1.505,-7.56 0,-4.06 1.47,-7.525 1.47,-3.465 4.095,-6.055 2.66,-2.59 6.195,-4.025 3.535,-1.435 7.665,-1.435 3.535,0 6.72,1.05 3.185,1.05 5.495,2.905 l -2.1,3.43 q -3.92,-3.045 -9.52,-3.045 -4.41,0 -7.77,1.96 -3.325,1.925 -5.18,5.285 -1.855,3.325 -1.855,7.455 0,4.165 1.89,7.525 1.89,3.325 5.25,5.25 3.36,1.925 7.7,1.925 2.73,0 5.145,-0.77 2.45,-0.805 4.305,-2.275 l 2.135,3.43 q -2.345,1.82 -5.495,2.87 -3.15,1.085 -6.72,1.085 z"
                        stroke="none"
                        fill="var(--global-text-color)"
                        id="path2858" />
                    <path
                        d="m 122.63984,1.12 q -5.6,0 -10.115,-2.415 -4.48,-2.415 -7.105,-6.72 -2.625,-4.305 -2.625,-9.905 0,-5.635 2.625,-9.905 2.625,-4.305 7.105,-6.72 4.48,-2.415 10.115,-2.415 5.6,0 10.08,2.415 4.515,2.415 7.14,6.72 2.625,4.27 2.625,9.905 0,5.6 -2.625,9.905 -2.625,4.305 -7.14,6.72 -4.48,2.415 -10.08,2.415 z m 0,-4.55 q 4.2,0 7.49,-1.855 3.29,-1.855 5.18,-5.11 1.89,-3.29 1.89,-7.525 0,-4.235 -1.89,-7.49 -1.89,-3.29 -5.18,-5.145 -3.29,-1.855 -7.49,-1.855 -4.2,0 -7.49,1.855 -3.29,1.855 -5.18,5.145 -1.89,3.255 -1.89,7.49 0,4.2 1.89,7.49 1.89,3.29 5.18,5.145 3.29,1.855 7.49,1.855 z"
                        fill="#00a99d"
                        stroke="#00a99d"
                        id="path2860" />
                    <path
                        d="m 157.70974,18.2 8.82,-19.075 -17.045,-34.965 h 5.775 l 14.175,29.715 12.88,-29.715 h 5.775 l -24.605,54.04 z"
                        stroke="none"
                        fill="var(--global-text-color)"
                        id="path2862" />
                    <path
                        d="m 215.03968,1.12 q -5.6,0 -10.115,-2.415 -4.48,-2.415 -7.105,-6.72 -2.625,-4.305 -2.625,-9.905 0,-5.635 2.625,-9.905 2.625,-4.305 7.105,-6.72 4.48,-2.415 10.115,-2.415 5.6,0 10.08,2.415 4.515,2.415 7.14,6.72 2.625,4.27 2.625,9.905 0,5.6 -2.625,9.905 -2.625,4.305 -7.14,6.72 -4.48,2.415 -10.08,2.415 z m 0,-4.55 q 4.2,0 7.49,-1.855 3.29,-1.855 5.18,-5.11 1.89,-3.29 1.89,-7.525 0,-4.235 -1.89,-7.49 -1.89,-3.29 -5.18,-5.145 -3.29,-1.855 -7.49,-1.855 -4.2,0 -7.49,1.855 -3.29,1.855 -5.18,5.145 -1.89,3.255 -1.89,7.49 0,4.2 1.89,7.49 1.89,3.29 5.18,5.145 3.29,1.855 7.49,1.855 z"
                        fill="#8d39e1"
                        stroke="#8d39e1"
                        id="path2864" />
                    <path
                        d="m 57.809985,30.32 q -1.28,0 -2.25,-0.38 -0.97,-0.39 -1.65,-0.93 -0.67,-0.55 -1.08,-1.07 -0.4,-0.52 -0.56,-0.78 l 0.87,-1.12 q 0.18,0.28 0.54,0.76 0.37,0.47 0.94,0.96 0.57,0.48 1.37,0.82 0.8,0.33 1.85,0.33 1,0 1.82,-0.36 0.83,-0.36 1.33,-1.07 0.5,-0.71 0.5,-1.76 0,-0.88 -0.35,-1.49 -0.34,-0.62 -0.95,-1.05 -0.61,-0.43 -1.4,-0.76 -0.78,-0.34 -1.64,-0.66 -0.6,-0.23 -1.26,-0.54 -0.65,-0.32 -1.22,-0.79 -0.57,-0.48 -0.93,-1.16 -0.35,-0.68 -0.35,-1.64 0,-0.9 0.34,-1.7 0.34,-0.8 0.95,-1.41 0.61,-0.62 1.46,-0.97 0.85,-0.35 1.88,-0.35 0.99,0 1.82,0.33 0.83,0.32 1.45,0.82 0.63,0.49 0.97,1.02 l -0.78,1.05 q -0.28,-0.46 -0.78,-0.88 -0.5,-0.42 -1.18,-0.68 -0.67,-0.27 -1.49,-0.27 -1.02,0 -1.74,0.44 -0.71,0.44 -1.08,1.14 -0.37,0.69 -0.37,1.46 0,0.7 0.29,1.19 0.29,0.49 0.77,0.83 0.48,0.33 1.05,0.57 0.58,0.24 1.16,0.45 0.97,0.36 1.86,0.77 0.9,0.4 1.6,0.96 0.7,0.55 1.11,1.35 0.41,0.79 0.41,1.92 0,1.48 -0.7,2.52 -0.7,1.04 -1.89,1.59 -1.19,0.54 -2.69,0.54 z"
                        stroke="none"
                        fill="var(--global-text-color)"
                        id="path2866" />
                    <path
                        d="m 71.709952,30.32 q -1.6,0 -2.89,-0.69 -1.28,-0.69 -2.03,-1.92 -0.75,-1.23 -0.75,-2.83 0,-1.61 0.75,-2.83 0.75,-1.23 2.03,-1.92 1.28,-0.69 2.89,-0.69 1.6,0 2.88,0.69 1.29,0.69 2.04,1.92 0.75,1.22 0.75,2.83 0,1.6 -0.75,2.83 -0.75,1.23 -2.04,1.92 -1.28,0.69 -2.88,0.69 z m 0,-1.3 q 1.2,0 2.14,-0.53 0.94,-0.53 1.48,-1.46 0.54,-0.94 0.54,-2.15 0,-1.21 -0.54,-2.14 -0.54,-0.94 -1.48,-1.47 -0.94,-0.53 -2.14,-0.53 -1.2,0 -2.14,0.53 -0.94,0.53 -1.48,1.47 -0.54,0.93 -0.54,2.14 0,1.2 0.54,2.14 0.54,0.94 1.48,1.47 0.94,0.53 2.14,0.53 z"
                        stroke="none"
                        fill="var(--global-text-color)"
                        id="path2868" />
                    <path
                        d="m 81.009902,30 v -9.14 h -1.03 v -1.1 h 1.03 v -3.67 q 0,-1.15 0.33,-1.95 0.33,-0.8 0.96,-1.22 0.63,-0.42 1.55,-0.42 0.57,0 1.06,0.16 0.49,0.15 0.86,0.44 l -0.51,1.04 q -0.46,-0.36 -1.16,-0.36 -0.56,0 -0.92,0.28 -0.36,0.27 -0.54,0.78 -0.17,0.51 -0.17,1.21 v 3.71 h 2.61 v 1.1 h -2.61 V 30 Z"
                        stroke="none"
                        fill="var(--global-text-color)"
                        id="path2870" />
                    <path
                        d="m 90.589893,30.32 q -1.15,0 -1.79,-0.77 -0.63,-0.78 -0.63,-2.19 v -6.5 h -1.04 v -1.1 h 1.04 v -3.83 h 1.46 v 3.83 h 2.55 v 1.1 h -2.55 v 6.42 q 0,0.85 0.33,1.3 0.33,0.45 0.94,0.45 0.59,0 1.04,-0.33 l 0.37,1.16 q -0.34,0.21 -0.8,0.33 -0.46,0.13 -0.92,0.13 z"
                        stroke="none"
                        fill="var(--global-text-color)"
                        id="path2872" />
                    <path
                        d="m 98.959876,30 -4.97,-10.24 h 1.67 l 3.75,8.14 3.230004,-8.14 h 0.98 l 3.23,8.14 3.75,-8.14 h 1.67 l -4.97,10.24 h -0.97 l -3.2,-7.97 -3.210004,7.97 z"
                        stroke="none"
                        fill="var(--global-text-color)"
                        id="path2874" />
                    <path
                        d="m 119.28981,30.32 q -1.44,0 -2.6,-0.7 -1.15,-0.71 -1.83,-1.94 -0.67,-1.23 -0.67,-2.81 0,-1.56 0.66,-2.78 0.67,-1.23 1.82,-1.94 1.16,-0.71 2.62,-0.71 1.39,0 2.52,0.65 1.13,0.65 1.72,1.8 v -2.13 h 1.46 V 30 h -1.46 v -2.16 q -0.66,1.16 -1.77,1.82 -1.11,0.66 -2.47,0.66 z m 0.36,-1.27 q 1.15,0 2.03,-0.53 0.89,-0.54 1.4,-1.48 0.51,-0.95 0.51,-2.16 0,-1.22 -0.51,-2.16 -0.5,-0.94 -1.39,-1.47 -0.89,-0.54 -2.04,-0.54 -1.15,0 -2.04,0.54 -0.89,0.53 -1.4,1.47 -0.51,0.94 -0.51,2.16 0,1.21 0.51,2.16 0.51,0.94 1.4,1.48 0.89,0.53 2.04,0.53 z"
                        stroke="none"
                        fill="var(--global-text-color)"
                        id="path2876" />
                    <path
                        d="M 129.28978,30 V 19.76 h 1.46 v 1.51 q 0.49,-0.9 1.26,-1.36 0.78,-0.47 1.8,-0.47 0.45,0 0.91,0.1 0.47,0.1 0.81,0.26 l -0.6,1.23 q -0.3,-0.15 -0.69,-0.23 -0.38,-0.08 -0.73,-0.08 -0.71,0 -1.34,0.36 -0.63,0.36 -1.03,0.94 -0.39,0.57 -0.39,1.22 V 30 Z"
                        stroke="none"
                        fill="var(--global-text-color)"
                        id="path2878" />
                    <path
                        d="m 142.89974,30.32 q -1.61,0 -2.88,-0.71 -1.26,-0.71 -1.98,-1.93 -0.72,-1.23 -0.72,-2.76 0,-1.15 0.41,-2.14 0.42,-1 1.16,-1.75 0.74,-0.75 1.72,-1.17 0.98,-0.42 2.09,-0.42 1.52,0 2.7,0.67 1.19,0.66 1.88,1.83 0.7,1.17 0.74,2.7 0,0.12 -0.01,0.2 0,0.07 -0.02,0.18 h -9.22 q 0,1.2 0.54,2.12 0.54,0.92 1.48,1.45 0.95,0.52 2.17,0.52 1.16,0 2.08,-0.48 0.93,-0.49 1.55,-1.4 l 1.08,0.58 q -0.73,1.19 -1.99,1.85 -1.25,0.66 -2.78,0.66 z m -4.05,-6.4 h 7.71 q -0.13,-0.96 -0.67,-1.69 -0.54,-0.74 -1.37,-1.16 -0.82,-0.42 -1.8,-0.42 -1.01,0 -1.84,0.42 -0.83,0.41 -1.37,1.15 -0.53,0.73 -0.66,1.7 z"
                        stroke="none"
                        fill="var(--global-text-color)"
                        id="path2880" />
                    <path
                        d="m 184.81973,30.32 q -1.72,0 -3.2,-0.66 -1.47,-0.66 -2.58,-1.83 -1.1,-1.17 -1.72,-2.72 -0.62,-1.56 -0.62,-3.35 0,-1.65 0.55,-3.19 0.55,-1.54 1.61,-2.74 1.06,-1.21 2.59,-1.92 1.54,-0.71 3.49,-0.71 1.28,0 2.42,0.32 1.14,0.32 2.09,0.89 0.96,0.57 1.67,1.34 l -0.79,1.16 q -0.94,-1.07 -2.36,-1.71 -1.42,-0.65 -3.05,-0.65 -1.56,0 -2.79,0.58 -1.22,0.58 -2.07,1.59 -0.85,1 -1.29,2.29 -0.44,1.28 -0.44,2.7 0,1.53 0.49,2.85 0.5,1.31 1.39,2.3 0.89,0.98 2.08,1.53 1.19,0.54 2.56,0.54 1.11,0 2.18,-0.37 1.07,-0.37 1.92,-1.07 0.86,-0.7 1.36,-1.67 0.51,-0.98 0.51,-2.2 v -0.57 h -6.08 v -1.23 h 7.64 v 1.47 q 0,1.75 -0.66,3.07 -0.66,1.32 -1.76,2.2 -1.1,0.87 -2.44,1.32 -1.34,0.44 -2.7,0.44 z"
                        stroke="none"
                        fill="var(--global-text-color)"
                        id="path2882" />
                    <path
                        d="M 196.07972,30 V 19.76 h 1.46 v 1.4 q 0.61,-0.85 1.46,-1.28 0.85,-0.44 1.9,-0.44 1.26,0 2.19,0.58 0.94,0.58 1.3,1.58 0.59,-0.98 1.5,-1.57 0.92,-0.59 2.18,-0.59 1.09,0 1.94,0.45 0.85,0.44 1.33,1.3 0.49,0.85 0.49,2.08 V 30 h -1.46 v -6.32 q 0,-1.4 -0.73,-2.22 -0.73,-0.82 -1.95,-0.82 -0.84,0 -1.52,0.37 -0.68,0.37 -1.08,0.96 -0.4,0.58 -0.4,1.2 V 30 h -1.46 v -6.47 q 0,-1.33 -0.73,-2.11 -0.72,-0.78 -1.91,-0.78 -0.84,0 -1.54,0.36 -0.69,0.35 -1.1,0.91 -0.41,0.56 -0.41,1.15 V 30 Z"
                        stroke="none"
                        fill="var(--global-text-color)"
                        id="path2884" />
                    <path
                        d="m 221.6797,30.32 q -1.36,0 -2.48,-0.66 -1.12,-0.66 -1.77,-1.82 V 30 h -1.46 V 12.8 h 1.46 v 9.09 q 0.63,-1.15 1.74,-1.8 1.11,-0.65 2.48,-0.65 1.47,0 2.63,0.71 1.16,0.71 1.82,1.94 0.67,1.22 0.67,2.79 0,1.58 -0.68,2.81 -0.67,1.23 -1.82,1.93 -1.15,0.7 -2.59,0.7 z m -0.36,-1.27 q 1.15,0 2.04,-0.53 0.89,-0.54 1.39,-1.48 0.51,-0.95 0.51,-2.15 0,-1.22 -0.51,-2.16 -0.51,-0.95 -1.4,-1.48 -0.88,-0.54 -2.03,-0.54 -1.15,0 -2.04,0.54 -0.89,0.53 -1.4,1.48 -0.51,0.94 -0.51,2.16 0,1.2 0.5,2.15 0.51,0.94 1.4,1.48 0.9,0.53 2.05,0.53 z"
                        stroke="none"
                        fill="var(--global-text-color)"
                        id="path2886" />
                    <path
                        d="M 230.87967,30 V 13.5 h 1.52 v 7.56 h 9.01 V 13.5 h 1.51 V 30 h -1.51 v -7.6 h -9.01 V 30 Z"
                        stroke="none"
                        fill="var(--global-text-color)"
                        id="path2888" />
                    <path
                        d="m 272.38963,30 6.5,-16.5 h 1.41 l 6.49,16.5 h -1.64 l -2.01,-5.26 h -7.09 l -2.03,5.26 z m 4.17,-6.6 h 6.06 l -3.03,-7.9 z"
                        stroke="none"
                        fill="var(--global-text-color)"
                        id="path2890" />
                    <path
                        d="m 294.54961,35.5 q -1.94,0 -3.28,-0.76 -1.34,-0.76 -1.99,-2.22 l 1.23,-0.57 q 0.47,1.06 1.56,1.68 1.09,0.63 2.48,0.63 1.17,0 2.09,-0.47 0.93,-0.46 1.46,-1.34 0.54,-0.88 0.54,-2.11 v -2.5 q -0.64,1.16 -1.76,1.82 -1.11,0.66 -2.48,0.66 -1.43,0 -2.59,-0.7 -1.15,-0.7 -1.83,-1.93 -0.67,-1.23 -0.67,-2.81 0,-1.57 0.67,-2.79 0.67,-1.23 1.82,-1.94 1.16,-0.71 2.63,-0.71 1.36,0 2.47,0.65 1.12,0.65 1.74,1.8 v -2.13 h 1.46 v 10.52 q 0,1.7 -0.67,2.86 -0.67,1.17 -1.92,1.76 -1.24,0.6 -2.96,0.6 z m 0.2,-6.45 q 1.16,0 2.05,-0.53 0.89,-0.54 1.4,-1.48 0.51,-0.95 0.51,-2.15 0,-1.22 -0.51,-2.16 -0.51,-0.95 -1.4,-1.48 -0.89,-0.54 -2.04,-0.54 -1.15,0 -2.04,0.54 -0.89,0.53 -1.4,1.48 -0.51,0.94 -0.51,2.16 0,1.2 0.5,2.15 0.51,0.94 1.4,1.48 0.9,0.53 2.04,0.53 z"
                        stroke="none"
                        fill="var(--global-text-color)"
                        id="path2892" />
                    <path
                        d="M 304.38957,30 V 19.76 h 1.46 V 30 Z m 0.74,-12.29 q -0.27,0 -0.49,-0.13 -0.21,-0.13 -0.34,-0.35 -0.13,-0.22 -0.13,-0.48 0,-0.26 0.13,-0.47 0.13,-0.22 0.34,-0.35 0.22,-0.13 0.49,-0.13 0.26,0 0.47,0.13 0.21,0.13 0.34,0.34 0.14,0.21 0.14,0.48 0,0.26 -0.14,0.48 -0.13,0.22 -0.34,0.35 -0.21,0.13 -0.47,0.13 z"
                        stroke="none"
                        fill="var(--global-text-color)"
                        id="path2894" />
                    <path
                        d="M 310.16956,30 V 12.8 h 1.46 V 30 Z"
                        stroke="none"
                        fill="var(--global-text-color)"
                        id="path2896" />
                    <path
                        d="m 320.73955,30.32 q -1.61,0 -2.88,-0.71 -1.26,-0.71 -1.98,-1.93 -0.72,-1.23 -0.72,-2.76 0,-1.15 0.41,-2.14 0.42,-1 1.16,-1.75 0.74,-0.75 1.72,-1.17 0.98,-0.42 2.09,-0.42 1.52,0 2.7,0.67 1.19,0.66 1.88,1.83 0.7,1.17 0.74,2.7 0,0.12 -0.01,0.2 0,0.07 -0.02,0.18 h -9.22 q 0,1.2 0.54,2.12 0.54,0.92 1.48,1.45 0.95,0.52 2.17,0.52 1.16,0 2.08,-0.48 0.93,-0.49 1.55,-1.4 l 1.08,0.58 q -0.73,1.19 -1.99,1.85 -1.25,0.66 -2.78,0.66 z m -4.05,-6.4 h 7.71 q -0.13,-0.96 -0.67,-1.69 -0.54,-0.74 -1.37,-1.16 -0.82,-0.42 -1.8,-0.42 -1.01,0 -1.84,0.42 -0.83,0.41 -1.37,1.15 -0.53,0.73 -0.66,1.7 z"
                        stroke="none"
                        fill="var(--global-text-color)"
                        id="path2898" />
                    <path
                        d="m 339.18954,30.32 q -1.15,0 -2.16,-0.4 -1.01,-0.41 -1.77,-1.15 -0.76,-0.74 -1.19,-1.73 -0.43,-0.99 -0.43,-2.16 0,-1.16 0.42,-2.15 0.42,-0.99 1.17,-1.73 0.76,-0.74 1.77,-1.15 1.01,-0.41 2.19,-0.41 1.01,0 1.92,0.3 0.91,0.3 1.57,0.83 l -0.6,0.98 q -1.12,-0.87 -2.72,-0.87 -1.26,0 -2.22,0.56 -0.95,0.55 -1.48,1.51 -0.53,0.95 -0.53,2.13 0,1.19 0.54,2.15 0.54,0.95 1.5,1.5 0.96,0.55 2.2,0.55 0.78,0 1.47,-0.22 0.7,-0.23 1.23,-0.65 l 0.61,0.98 q -0.67,0.52 -1.57,0.82 -0.9,0.31 -1.92,0.31 z"
                        stroke="none"
                        fill="var(--global-text-color)"
                        id="path2900" />
                    <path
                        d="m 350.74953,30.32 q -1.6,0 -2.89,-0.69 -1.28,-0.69 -2.03,-1.92 -0.75,-1.23 -0.75,-2.83 0,-1.61 0.75,-2.83 0.75,-1.23 2.03,-1.92 1.28,-0.69 2.89,-0.69 1.6,0 2.88,0.69 1.29,0.69 2.04,1.92 0.75,1.22 0.75,2.83 0,1.6 -0.75,2.83 -0.75,1.23 -2.04,1.92 -1.28,0.69 -2.88,0.69 z m 0,-1.3 q 1.2,0 2.14,-0.53 0.94,-0.53 1.48,-1.46 0.54,-0.94 0.54,-2.15 0,-1.21 -0.54,-2.14 -0.54,-0.94 -1.48,-1.47 -0.94,-0.53 -2.14,-0.53 -1.2,0 -2.14,0.53 -0.94,0.53 -1.48,1.47 -0.54,0.93 -0.54,2.14 0,1.2 0.54,2.14 0.54,0.94 1.48,1.47 0.94,0.53 2.14,0.53 z"
                        stroke="none"
                        fill="var(--global-text-color)"
                        id="path2902" />
                    <path
                        d="m 364.30949,30.32 q -1.44,0 -2.6,-0.7 -1.15,-0.7 -1.83,-1.93 -0.67,-1.23 -0.67,-2.81 0,-1.57 0.67,-2.79 0.67,-1.23 1.82,-1.94 1.16,-0.71 2.63,-0.71 1.37,0 2.48,0.65 1.11,0.65 1.74,1.8 v -9.1 h 1.46 V 30 h -1.46 v -2.16 q -0.65,1.16 -1.77,1.82 -1.11,0.66 -2.47,0.66 z m 0.35,-1.27 q 1.15,0 2.04,-0.53 0.89,-0.54 1.4,-1.48 0.51,-0.95 0.51,-2.15 0,-1.22 -0.51,-2.16 -0.5,-0.95 -1.4,-1.48 -0.89,-0.54 -2.04,-0.54 -1.14,0 -2.03,0.54 -0.89,0.53 -1.4,1.48 -0.51,0.94 -0.51,2.16 0,1.21 0.5,2.15 0.51,0.94 1.4,1.48 0.9,0.53 2.04,0.53 z"
                        stroke="none"
                        fill="var(--global-text-color)"
                        id="path2904" />
                    <path
                        d="M 374.29945,30 V 19.76 h 1.46 V 30 Z m 0.74,-12.29 q -0.27,0 -0.49,-0.13 -0.21,-0.13 -0.34,-0.35 -0.13,-0.22 -0.13,-0.48 0,-0.26 0.13,-0.47 0.13,-0.22 0.34,-0.35 0.22,-0.13 0.49,-0.13 0.26,0 0.47,0.13 0.21,0.13 0.34,0.34 0.14,0.21 0.14,0.48 0,0.26 -0.14,0.48 -0.13,0.22 -0.34,0.35 -0.21,0.13 -0.47,0.13 z"
                        stroke="none"
                        fill="var(--global-text-color)"
                        id="path2906" />
                    <path
                        d="M 380.07944,30 V 19.76 h 1.46 v 1.4 q 0.42,-0.72 1.3,-1.22 0.88,-0.5 2.15,-0.5 1.19,0 2.05,0.49 0.86,0.49 1.32,1.43 0.46,0.93 0.46,2.25 V 30 h -1.46 v -6.11 q 0,-1.59 -0.74,-2.42 -0.74,-0.84 -2.09,-0.84 -0.94,0 -1.61,0.38 -0.66,0.37 -1.02,0.99 -0.36,0.61 -0.36,1.33 V 30 Z"
                        stroke="none"
                        fill="var(--global-text-color)"
                        id="path2908" />
                    <path
                        d="m 397.45946,35.5 q -1.94,0 -3.28,-0.76 -1.34,-0.76 -1.99,-2.22 l 1.23,-0.57 q 0.47,1.06 1.56,1.68 1.09,0.63 2.48,0.63 1.17,0 2.09,-0.47 0.93,-0.46 1.46,-1.34 0.54,-0.88 0.54,-2.11 v -2.5 q -0.64,1.16 -1.76,1.82 -1.11,0.66 -2.48,0.66 -1.43,0 -2.59,-0.7 -1.15,-0.7 -1.83,-1.93 -0.67,-1.23 -0.67,-2.81 0,-1.57 0.67,-2.79 0.67,-1.23 1.82,-1.94 1.16,-0.71 2.63,-0.71 1.36,0 2.47,0.65 1.12,0.65 1.74,1.8 v -2.13 h 1.46 v 10.52 q 0,1.7 -0.67,2.86 -0.67,1.17 -1.92,1.76 -1.24,0.6 -2.96,0.6 z m 0.2,-6.45 q 1.16,0 2.05,-0.53 0.89,-0.54 1.4,-1.48 0.51,-0.95 0.51,-2.15 0,-1.22 -0.51,-2.16 -0.51,-0.95 -1.4,-1.48 -0.89,-0.54 -2.04,-0.54 -1.15,0 -2.04,0.54 -0.89,0.53 -1.4,1.48 -0.51,0.94 -0.51,2.16 0,1.2 0.5,2.15 0.51,0.94 1.4,1.48 0.9,0.53 2.04,0.53 z"
                        stroke="none"
                        fill="var(--global-text-color)"
                        id="path2910" />
                    <path
                        d="m 412.65941,30 v -9.14 h -1.03 v -1.1 h 1.03 v -3.67 q 0,-1.15 0.33,-1.95 0.33,-0.8 0.96,-1.22 0.63,-0.42 1.55,-0.42 0.57,0 1.06,0.16 0.49,0.15 0.86,0.44 l -0.51,1.04 q -0.46,-0.36 -1.16,-0.36 -0.56,0 -0.92,0.28 -0.36,0.27 -0.54,0.78 -0.17,0.51 -0.17,1.21 v 3.71 h 2.61 v 1.1 h -2.61 V 30 Z"
                        stroke="none"
                        fill="var(--global-text-color)"
                        id="path2912" />
                    <path
                        d="m 424.5794,30.32 q -1.6,0 -2.89,-0.69 -1.28,-0.69 -2.03,-1.92 -0.75,-1.23 -0.75,-2.83 0,-1.61 0.75,-2.83 0.75,-1.23 2.03,-1.92 1.28,-0.69 2.89,-0.69 1.6,0 2.88,0.69 1.29,0.69 2.04,1.92 0.75,1.22 0.75,2.83 0,1.6 -0.75,2.83 -0.75,1.23 -2.04,1.92 -1.28,0.69 -2.88,0.69 z m 0,-1.3 q 1.2,0 2.14,-0.53 0.94,-0.53 1.48,-1.46 0.54,-0.94 0.54,-2.15 0,-1.21 -0.54,-2.14 -0.54,-0.94 -1.48,-1.47 -0.94,-0.53 -2.14,-0.53 -1.2,0 -2.14,0.53 -0.94,0.53 -1.48,1.47 -0.54,0.93 -0.54,2.14 0,1.2 0.54,2.14 0.54,0.94 1.48,1.47 0.94,0.53 2.14,0.53 z"
                        stroke="none"
                        fill="var(--global-text-color)"
                        id="path2914" />
                    <path
                        d="M 433.72935,30 V 19.76 h 1.46 v 1.51 q 0.49,-0.9 1.26,-1.36 0.78,-0.47 1.8,-0.47 0.45,0 0.91,0.1 0.47,0.1 0.81,0.26 l -0.6,1.23 q -0.3,-0.15 -0.69,-0.23 -0.38,-0.08 -0.73,-0.08 -0.71,0 -1.34,0.36 -0.63,0.36 -1.03,0.94 -0.39,0.57 -0.39,1.22 V 30 Z"
                        stroke="none"
                        fill="var(--global-text-color)"
                        id="path2916" />
                    <path
                        d="m 448.50933,35.2 2.52,-5.45 -4.87,-9.99 h 1.65 l 4.05,8.49 3.68,-8.49 h 1.65 l -7.03,15.44 z"
                        stroke="none"
                        fill="var(--global-text-color)"
                        id="path2918" />
                    <path
                        d="m 464.82931,30.32 q -1.6,0 -2.89,-0.69 -1.28,-0.69 -2.03,-1.92 -0.75,-1.23 -0.75,-2.83 0,-1.61 0.75,-2.83 0.75,-1.23 2.03,-1.92 1.28,-0.69 2.89,-0.69 1.6,0 2.88,0.69 1.29,0.69 2.04,1.92 0.75,1.22 0.75,2.83 0,1.6 -0.75,2.83 -0.75,1.23 -2.04,1.92 -1.28,0.69 -2.88,0.69 z m 0,-1.3 q 1.2,0 2.14,-0.53 0.94,-0.53 1.48,-1.46 0.54,-0.94 0.54,-2.15 0,-1.21 -0.54,-2.14 -0.54,-0.94 -1.48,-1.47 -0.94,-0.53 -2.14,-0.53 -1.2,0 -2.14,0.53 -0.94,0.53 -1.48,1.47 -0.54,0.93 -0.54,2.14 0,1.2 0.54,2.14 0.54,0.94 1.48,1.47 0.94,0.53 2.14,0.53 z"
                        stroke="none"
                        fill="var(--global-text-color)"
                        id="path2920" />
                    <path
                        d="m 478.15926,30.32 q -2.33,0 -3.33,-1.05 -1,-1.05 -1,-3.51 v -6 h 1.46 v 5.74 q 0,1.94 0.66,2.77 0.66,0.82 2.21,0.82 1.56,0 2.21,-0.82 0.66,-0.83 0.66,-2.77 v -5.74 h 1.46 v 6 q 0,2.46 -1,3.51 -0.99,1.05 -3.33,1.05 z"
                        stroke="none"
                        fill="var(--global-text-color)"
                        id="path2922" />
                    <path
                        d="m 488.27926,30.32 q -0.29,0 -0.53,-0.14 -0.23,-0.14 -0.37,-0.37 -0.13,-0.24 -0.13,-0.52 0,-0.28 0.13,-0.51 0.14,-0.23 0.38,-0.37 0.24,-0.14 0.51,-0.14 0.29,0 0.52,0.14 0.23,0.14 0.36,0.37 0.14,0.23 0.14,0.51 0,0.28 -0.14,0.52 -0.13,0.23 -0.36,0.37 -0.23,0.14 -0.51,0.14 z"
                        stroke="none"
                        fill="var(--global-text-color)"
                        id="path2924" />
                    </g>
                    <path
                    id="Ellipse_1"
                    transform="translate(103.418 27)"
                    fill="#00a99d"
                    d="M 34.75,17.375 A 17.375,17.375 0 0 1 17.375,34.75 17.375,17.375 0 0 1 0,17.375 17.375,17.375 0 0 1 17.375,0 17.375,17.375 0 0 1 34.75,17.375 Z" />
                    <path
                    id="Ellipse_2"
                    transform="translate(195.418 27)"
                    fill="#8d39e1"
                    d="M 34.75,17.375 A 17.375,17.375 0 0 1 17.375,34.75 17.375,17.375 0 0 1 0,17.375 17.375,17.375 0 0 1 17.375,0 17.375,17.375 0 0 1 34.75,17.375 Z" />
                    <path
                    id="Linie_1"
                    transform="translate(257.418 71.5)"
                    fill="none"
                    stroke="var(--global-text-color)"
                    strokeMiterlimit="10"
                    d="M 0,0 V 27" />
                </g>
                </svg>           
        )
    }
}

export default Schriftzug;