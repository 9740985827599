import React, { useState, useEffect } from 'react';
import './App.css';
import './globalCssVars.css';
import Main from './Komponenten/mainComponent';
import {
  HashRouter as Router,
  Switch,
  Route,
} from "react-router-dom";
import BuecherService from './Logik/BuecherService';
import AxiosInstanceFactory from './Logik/AxiosInstanceFactory';
import BuchmelderDb from './Logik/BuchmelderDb';
import ServiceClient from './Logik/ServiceClient';
import SubscriptionService from './Logik/SubscriptionService';
import Details from './Komponenten/detailsComponent';
import AutorenService from './Logik/AutorenService';
import NutzerService from './Logik/NutzerService';
import HeaderComponent from './Komponenten/headerComponent';
import ScrollPositionHandler from './Logik/ScrollPositionHandler';
import NotSendRequestService from './Logik/NotSendRequestService';
import Config from './Komponenten/configComponent';
import Impressum from './Komponenten/impressum';
import UeberDieApp from './Komponenten/ueberDieApp';
import UeberAcoyo from './Komponenten/ueberAcoyo';
import Merkliste from './Komponenten/merklistenComponent';
import AlleAutorenBuecher from './Komponenten/alleAutorenBuecherComponent';
import VorschlagsAutorenService from './Logik/VorschlagsAutorenService';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import MerklistenService from './Logik/MerklistenService';
import { ThemeProvider } from '@material-ui/core/styles';
import { createMuiTheme } from '@material-ui/core/styles';
import ThemeService from './Logik/ThemeService';
import Welcome from './Komponenten/welcome';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
function App() {
    const [showReload, setShowReload] = useState(false);
    const [showWelcome, setShowWelcome] = useState(false);

    const onSWUpdate = (registration: ServiceWorkerRegistration) => {
        setShowReload(true);
        setTimeout(() => 
        {
            registration.waiting?.postMessage({ type: 'SKIP_WAITING' });
            setShowReload(false);
            window.location.reload();
        }, 3500);
      };
      
    const axiosInstanceFactory = new AxiosInstanceFactory();
    const db = new BuchmelderDb();
    const serviceClient = new ServiceClient(axiosInstanceFactory, db);    
    const nutzerService = new NutzerService(serviceClient, db);
    const autorenService = new AutorenService(serviceClient, db, nutzerService);
    const subscriptionService = new SubscriptionService(serviceClient, nutzerService, db);
    const buchService = new BuecherService(serviceClient, db);
    const scrollHandler = new ScrollPositionHandler();
    const notSendRequestService = new NotSendRequestService(serviceClient, db);
    const vorschlagsAutorService = new VorschlagsAutorenService(serviceClient, db);
    const merklistenService = new MerklistenService(serviceClient, db, nutzerService);
    const themeService =  new ThemeService(db);    

    useEffect(() => {
        // If you want your app to work offline and load faster, you can change
        // unregister() to register() below. Note this comes with some pitfalls.
        // Learn more about service workers: https://cra.link/PWA
        serviceWorkerRegistration.register({ onUpdate: onSWUpdate });
        const getBuecherVorhanden = async () => {
            let buecherVorhanden = await buchService.buecherVorhanden();
            setShowWelcome(!buecherVorhanden)
        }
        getBuecherVorhanden();
    }, []);

    //not awaiting because these sould run in background
    notSendRequestService.sendAllOpenRequests();
    db.removeOldData();
   
    const theme = createMuiTheme({
        palette: {
          primary: {
            main: "#00857C",
            light: "#888",
            dark: "#888",
            contrastText: "#888"
          },
          secondary: {
            main: "#00857C",
            light: "#888",
            dark: "#888",
            contrastText: "#888"
          },
        },
      });    

    themeService.setCurrentTheme();

    return <div className="wrapper">
            <Router>
                {!showWelcome && <HeaderComponent showReload={showReload}/>}
                <Switch>            
                    <Route path="/Konfiguration">
                    <ThemeProvider theme={theme}>
                        <Config autorenService={autorenService} 
                                subscriptionService={subscriptionService}
                                vorschlagsAutorService={vorschlagsAutorService}
                                themeService={themeService}/> 
                    </ThemeProvider>
                    </Route>
                    <Route path="/Impressum">
                        <Impressum />
                    </Route> 
                    <Route path="/UeberDieApp">
                        <UeberDieApp />
                    </Route> 
                    <Route path="/UeberAcoyo">
                        <UeberAcoyo />
                    </Route>
                    <Route path="/Details">
                        <Details buchServive={buchService} merklistenService={merklistenService}
                        />
                    </Route>
                    <Route path="/Merkliste">
                        <Merkliste merklistenService={merklistenService}
                        scrollPositionHandler={scrollHandler} />
                    </Route>                    
                    <Route path="/AlleBuecher"
                        render={(props) => <AlleAutorenBuecher {...props} 
                        buecherService={buchService}
                        scrollPositionHandler={scrollHandler} />}>
                    </Route>                      
                    <Route path="/Buecher">
                        <Main buchService={buchService}
                            autorenService={autorenService}
                            subscriptionService={subscriptionService}
                            scrollPositionHandler={scrollHandler}
                            vorschlagsAutorService={vorschlagsAutorService}/>
                    </Route>
                    <Route path="">
                        {showWelcome ?
                            <Welcome buchService={buchService} redirected={async () => {await setShowWelcome(false); history.pushState({}, "", '/Buecher')}}/>
                            : <Main buchService={buchService}
                            autorenService={autorenService}
                            subscriptionService={subscriptionService}
                            scrollPositionHandler={scrollHandler}
                            vorschlagsAutorService={vorschlagsAutorService}/>
                        }
                    </Route> 
                </Switch>
            </Router>
        </div>;
}

export default App;
