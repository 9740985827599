import * as React from "react";
import "./buchComponent.css";
import "./shared.css";
import Buch from "../Datenmodell/Buch";
import {Link} from "react-router-dom";
import moment from 'moment';

export interface IBuchComponentProps {    
    buch: Buch,
    showDate: boolean
}


class BuchComponent extends React.Component<IBuchComponentProps>{

    constructor(props: IBuchComponentProps) {
        super(props);
    }

    async componentDidMount() {        
       
    }

    componentWillUnmount() {
    }
  
    render() { 
        const thumbnailUrl = this.props.buch.bigThumbnailUrl ? this.props.buch.bigThumbnailUrl : 
                                    (this.props.buch.smallThumbnailUrl ? this.props.buch.smallThumbnailUrl : "BuchDefault.png");
        let date = moment(this.props.buch?.datumVeroeffentlichung, "YYYY-MM-DD");
        let dateString = date.format("DD.MM.yyyy");
        let isNotYetPublished = date > moment();
        const image = <img src={thumbnailUrl} className={thumbnailUrl === "BuchDefault.png" ? "defaultImage" : "buchImage"} height="80px" width="50px" alt="Buchcover"></img>

        return <div className="buchCard" key={this.props.buch.id}>
                    <Link className={this.props.showDate ? "buchCardInner" : "buchCardInnerHeute"} to={"/Details?id=" + this.props.buch.id}>
                        <div className="buchImageWrapper">{image}</div>
                        <div className={this.props.showDate ? "cardContent" : "cardContentHeute"}>
                            <div className={this.props.showDate ? "autor" : "autorHeute"}>{this.props.buch.autoren.map(a => a.name).join(", ")}</div>
                            <div className="titel">{this.props.buch.titel}</div>
                            <div className="subTitel">{this.props.buch.subTitel}</div>
                        </div> 
                        {this.props.showDate && <div className={isNotYetPublished ? "notYetPublishedDate" : "date"}>{dateString}</div> }
                    </Link>                   
                </div>;
    }
}
export default BuchComponent;