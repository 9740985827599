import { ColorTheme } from "./ColorTheme";

export default class SelectedColorTheme 
{
    public theme: ColorTheme;

    public constructor(theme: ColorTheme) 
    {
        this.theme = theme;
    }
}