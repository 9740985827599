export let dialogStyles = {
    backgroundColor: 'var(--global-white)',
    color: 'var(--global-text-color)',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: "center",
    alignItems: "center",
    maxWidth: "400px",
    minWidth: "350px",
    minHeight: "10px",
    position: "relative",
    marginLeft: "auto",
    marginRight: "auto",
    left: "auto"
  };

export let closeButtonStyles = {
    fontSize: "3.5rem",
    right: "7px",
    top: "-7px"
  }