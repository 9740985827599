import * as React from "react";
import "./mainComponent.css";
import "./shared.css";
import Buch from "../Datenmodell/Buch";
import Autor from "../Datenmodell/Autor";
import ScrollPosition from "../Datenmodell/ScrollPosition";
import BuchService from "../Logik/BuecherService";
import AutorenService from "../Logik/AutorenService";
import SubscriptionService from "../Logik/SubscriptionService";
import ScrollPositionHandler from "../Logik/ScrollPositionHandler";
import {ShowType} from "../Datenmodell/ShowType";
import {Zeitraum} from '../Datenmodell/Zeitraum';
import BuchComponent from './buchComponent';
import PulseLoader from "react-spinners/PulseLoader";
import AutoreFilter from "./autorenFilterComponent";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUp } from '@fortawesome/free-solid-svg-icons';
import BuchError from '../Datenmodell/BuchError';
import { makeBuchDTOOutOfBuch } from "../Datenmodell/BuchDTO";
import VorschlagsAutorenService from "../Logik/VorschlagsAutorenService";
import UrlExtractor from "../Logik/UrlExtractor";
import MerklistenOverlay from '../Komponenten/merklistenOverlayComponent';
import moment from 'moment';
import { makeAutorDTOFromAutor } from "../Datenmodell/AutorDTO";


export interface IMainProps 
{
    buchService: BuchService,
    autorenService: AutorenService,
    subscriptionService: SubscriptionService;
    scrollPositionHandler: ScrollPositionHandler;
    vorschlagsAutorService: VorschlagsAutorenService;
}

export interface IMainState 
{
    buchListe: Array<Buch>,
    showType: ShowType,
    keineBuecherNachricht: string | null,
    zeitraum: Zeitraum,
    autorenFilter: Array<Autor>,
    loading: boolean,
    showScrollUpButton: boolean,
}

class Main extends React.Component<IMainProps, IMainState>
{
    private initialScrollPosition = 0;

    constructor(props: IMainProps) 
    {
        super(props);    

        this.state = 
        {
            buchListe: new Array<Buch>(),
            showType: ShowType.Alle,
            zeitraum: Zeitraum.Heute,
            keineBuecherNachricht: null,
            autorenFilter: new Array<Autor>(),
            loading: true,
            showScrollUpButton: false,
        };
    }

    async componentDidMount() 
    {        
        this.addScrollEventListener();
      
        let autorenFilter = await this.props.autorenService.getAutorenFilter();
        this.setState({autorenFilter: autorenFilter});
        const show: ShowType = UrlExtractor.extrahiereShowTypeAusUrl(window.location.href);
        const zeitraum: Zeitraum = UrlExtractor.extrahiereZeitraumAusUrl(window.location.href);
        await this.ladeBuecher(show, zeitraum);

        await this.scrollZuLetzterPosition();

        this.doBackgroundStuff();
    }   

    async componentWillUnmount() 
    {
        window.removeEventListener("scroll", () => this.handleScroll());
        await this.props.scrollPositionHandler.speichereLetzteScrollPosition(new ScrollPosition(window.pageYOffset));
    }
  
    
    render() 
    {
        const buchParts = this.state.buchListe.map((buch) => <BuchComponent buch={buch} key={buch.id} showDate={this.state.zeitraum != Zeitraum.Heute ? true : false}/> );       
        
        return <div className="content">           
            <div className="buecherTabs">
                <button type="button" id="alleButton"
                    className={this.state.showType == ShowType.Alle ? "tabButton active" : "tabButton"} 
                    onClick={() => this.ladeBuecher(ShowType.Alle, this.state.zeitraum)}
                    onKeyUp={(event: any) => {if(event.keyCode === 13) this.ladeBuecher(ShowType.Alle, this.state.zeitraum)}}
                    disabled={this.state.loading  && this.state.showType != ShowType.Alle}>Alle Bücher</button>
                <button type="button" id="nachAutorenButton"
                    className={this.state.showType == ShowType.NachAutoren ? "tabButton active" : "tabButton"}
                    onClick={() => this.ladeBuecher(ShowType.NachAutoren, this.state.zeitraum)}
                    onKeyUp={(event: any) => {if(event.keyCode === 13) this.ladeBuecher(ShowType.NachAutoren, this.state.zeitraum)}}
                    disabled={this.state.loading && this.state.showType != ShowType.NachAutoren}>Bücher meiner Autor*innen</button>
            </div>
            <div className="zeitraumTabs">
                <button type="button" id="heuteButton"
                    className={this.state.zeitraum == Zeitraum.Heute ? "tabButtonZeit activeZeit" : "tabButtonZeit"} 
                    onClick={() => this.ladeBuecher(this.state.showType, Zeitraum.Heute)}
                    onKeyUp={(event: any) => {if(event.keyCode === 13) this.ladeBuecher(this.state.showType, Zeitraum.Heute)}}
                    disabled={this.state.loading && this.state.zeitraum != Zeitraum.Heute}>Heute</button>
                <button type="button" id="siebenTageButton"
                    className={this.state.zeitraum == Zeitraum.SiebenTage ? "tabButtonZeit activeZeit" : "tabButtonZeit"} 
                    onClick={() => this.ladeBuecher(this.state.showType, Zeitraum.SiebenTage)}
                    onKeyUp={(event: any) => {if(event.keyCode === 13) this.ladeBuecher(this.state.showType, Zeitraum.SiebenTage)}}
                    disabled={this.state.loading && this.state.zeitraum != Zeitraum.SiebenTage}>Letzte 7 Tage</button>
                <button type="button" id="dreißigTageButton"
                    className={this.state.zeitraum == Zeitraum.DreißigTage ? "tabButtonZeit activeZeit" : "tabButtonZeit"} 
                    onClick={() => this.ladeBuecher(this.state.showType, Zeitraum.DreißigTage)}
                    onKeyUp={(event: any) => {if(event.keyCode === 13) this.ladeBuecher(this.state.showType, Zeitraum.DreißigTage)}}
                    disabled={this.state.loading && this.state.zeitraum != Zeitraum.DreißigTage}>Letzte 30 Tage</button>
            </div>

            {this.state.showType == ShowType.NachAutoren &&
                <AutoreFilter autorenService={this.props.autorenService} vorschlagsAutorService={this.props.vorschlagsAutorService} subscriptionService={this.props.subscriptionService} somethingChanged={async () => await this.autorenFilterChanged()} parentLoading={this.state.loading}/>
            }

            {this.state.loading && this.state.showType != ShowType.NachAutoren && <div className="loadingWrapper"><PulseLoader color={"var(--global-white)"} loading={this.state.loading} size={30} speedMultiplier={0.6} margin={20}/></div>}

            {this.state.buchListe && this.state.buchListe.length > 0 && 
                <div className="buecherWrapper">
                    {buchParts}
                </div>
            }            
            {(!this.state.buchListe || this.state.buchListe.length == 0) && this.state.keineBuecherNachricht && 
                <div className="messageWrapper" id="messageWrapper">
                    {this.state.keineBuecherNachricht}
                    {this.state.showType == ShowType.NachAutoren && 
                        <div className="alleAutorenBuecherHelp">
                                Um alle Bücher eines/einer Autor*in zu sehen, wähle sie/ihn oben aus.
                        </div>
                    }
                    <img className="emptyImage" src="Buchstapel.png" height="300" alt="Bücherstapel"/>
                </div>
            }            
            {this.state.showScrollUpButton && 
                <button type="button" className="scrollToTopButton" 
                    onClick={() => this.scrollToTop()}
                    onKeyUp={(event: any) => {if(event.keyCode === 13) this.scrollToTop()}}>
                        <FontAwesomeIcon icon={faArrowUp} size="1x"/> 
                        Zum Anfang 
                        <FontAwesomeIcon icon={faArrowUp} size="1x"/>
                </button>
            }
            <MerklistenOverlay/>
        </div>;
    }

    private async ladeBuecher(show: ShowType, zeitraum: Zeitraum): Promise<void> 
    {
        this.props.buchService.cancelOldRequest();
        this.setState({buchListe: new Array<Buch>(), showType: show, zeitraum: zeitraum, keineBuecherNachricht: null, loading: true});
        let buecher: Array<Buch> | BuchError; 
        if (show && show === ShowType.Alle) 
        {
            switch(zeitraum) 
            {
                case Zeitraum.Heute: buecher = await this.props.buchService.holeHeutigeBuecher(); break;
                case Zeitraum.SiebenTage: buecher = await this.props.buchService.holeBuecherLetzteTage(7); break;
                case Zeitraum.DreißigTage: buecher = await this.props.buchService.holeBuecherLetzteTage(30); break;                
            }
        }        
        else 
        {
            switch(zeitraum) 
            {
                case Zeitraum.Heute: buecher = await this.props.buchService.holeBuecherVonAutorenFuerHeute(); break;
                case Zeitraum.SiebenTage: buecher = await this.props.buchService.holeBuecherVonAutorenFuerZeitraum(7); break;
                case Zeitraum.DreißigTage: buecher = await this.props.buchService.holeBuecherVonAutorenFuerZeitraum(30); break;
            }
        }

        if(buecher instanceof BuchError)
        {
            let errorNachricht = this.generiereErrorNachricht(buecher as BuchError);
            this.setState({buchListe: new Array<Buch>(), keineBuecherNachricht: errorNachricht, showType: show, zeitraum: zeitraum, loading: false});
        }
        else
        {
            buecher = buecher as Array<Buch>;
            let nachricht = buecher && buecher.length > 0 ? null : this.generiereEmptyNachricht(show, zeitraum);
            buecher.sort((a, b) => (Buch.compareBuecher(a, b)));
            this.addClickToBrowserHistory(show, zeitraum);            
            this.setState({buchListe: buecher, keineBuecherNachricht: nachricht, showType: show, zeitraum: zeitraum, loading: false});          
        }
    }

    private generiereEmptyNachricht(show: ShowType, zeitraum: Zeitraum): string 
    {
        let nachricht = "";
        if (show && show === ShowType.Alle) 
        {
            switch(zeitraum) 
            {
                case Zeitraum.Heute: nachricht = "Es wurden für heute keine neuen Bücher gefunden. Schaue gern nach welche Bücher vielleicht in den letzten Tagen erschienen sind."; break;
                case Zeitraum.SiebenTage: nachricht = "Es wurden in diesem Zeitraum leider keine neuen Bücher gefunden."; break;
                case Zeitraum.DreißigTage: nachricht = "Es wurden in diesem Zeitraum leider keine neuen Bücher gefunden."; break;
                default: nachricht = ""; break;
            }
        }        
        else 
        {
            if (!this.state.autorenFilter || this.state.autorenFilter.length == 0) return "";
            switch(zeitraum) 
            {
                case Zeitraum.Heute: nachricht = "Es wurden für heute leider keine neuen Bücher von deinen Lieblingsautor*innen gefunden."; break;
                case Zeitraum.SiebenTage: nachricht = "Es wurden in diesem Zeitraum leider keine neuen Bücher von deinen Lieblingsautor*innen gefunden."; break;
                case Zeitraum.DreißigTage: nachricht = "Es wurden in diesem Zeitraum leider keine neuen Bücher von deinen Lieblingsautor*innen gefunden."; break;
                default: nachricht = ""; break;
            }
        }
        return nachricht;
    }

    private generiereErrorNachricht(error: BuchError): string
    {
        let nachricht = "";
        if(error.offline) nachricht = "Bücher konnten nicht geladen werden. Prüfe bitte deine Internetverbindung.";
        else nachricht = "Irgendetwas ist schief gelaufen. Tut uns leid. Probiere es einfach später nochmal."
        return nachricht;
    }

    private async filterForAutor(autor: Autor | null)
    {
        if(autor)
        {
            let autorArray = new Array<Autor>();
            autorArray.push(autor);
            await this.ladeBuecher(this.state.showType, this.state.zeitraum);
            let buecher =  this.state.buchListe.map(b => makeBuchDTOOutOfBuch(b));
            let filteredBuecher = this.props.buchService.filterBuecherNachAutoren(buecher, autorArray.map(a => makeAutorDTOFromAutor(a)));
            this.setState({buchListe: filteredBuecher.map(bdto => new Buch(bdto))});
        }
        else
        {
            await this.ladeBuecher(this.state.showType, this.state.zeitraum);
        }
    }

    private addClickToBrowserHistory(show: ShowType, zeitraum: Zeitraum): void 
    {
        window.history.replaceState(null, "", "#/?show=" + show + "&zeitraum=" + zeitraum);
    }

    private async autorenFilterChanged(): Promise<void> 
    {
        let autorenFilter = await this.props.autorenService.getAutorenFilter();
        this.setState({autorenFilter: autorenFilter});
        this.ladeBuecher(this.state.showType, this.state.zeitraum);
    }

    private scrollToTop() 
    {
        window.scrollTo(0, 0);
        document?.querySelector("body")?.scrollTo(0, 0);
    }

    private handleScroll() 
    {
        if (window.scrollY > 1000) {
            if(!this.state.showScrollUpButton) this.setState({showScrollUpButton: true});
        } else {
            if(this.state.showScrollUpButton) this.setState({showScrollUpButton: false});
        }
    }

    private addScrollEventListener() 
    {
        window.addEventListener("scroll", () => this.handleScroll());
    }

    private async scrollZuLetzterPosition() 
    {
        this.initialScrollPosition = (await this.props.scrollPositionHandler.getLetzteScrollPosition()).value;
        window.scrollTo(0, this.initialScrollPosition);
        document?.querySelector("body")?.scrollTo(0, this.initialScrollPosition);
    }

    private doBackgroundStuff() 
    {
        if(sessionStorage.getItem("alreadyCalled") == null) 
        {
            const heute = moment().format('YYYY-MM-DD');
            this.props.buchService.holeBuecherVonServiceUndSpeicherSie(30, heute, false);
            this.props.vorschlagsAutorService.ladeAutoren();
            this.props.subscriptionService.checkIfNutzerIsKnownInBackendIfSubscribed();
            sessionStorage.setItem("alreadyCalled", "true");
        }
    }
}
export default Main;