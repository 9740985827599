export enum ShowType {
    Alle = "alle",
    NachAutoren = "nachautoren"
}

export function mapStringToEnum(str: string | null): ShowType {
    switch (str) {
        case "alle": return ShowType.Alle;
        case "nachautoren": return ShowType.NachAutoren;
        default: return ShowType.Alle;
    }
        
}