
import { ColorTheme } from '../Datenmodell/ColorTheme';
import BuchmelderDb from './BuchmelderDb';

export default class ServiceClient 
{    
    private db: BuchmelderDb;

    public constructor(db: BuchmelderDb) 
    {        
        this.db = db;
    }

    public async setTheme(theme: ColorTheme)
    {
        await this.db.speichereTheme(theme);
        if(theme == ColorTheme.Hell) document.documentElement.setAttribute('data-theme', ColorTheme.Hell);
        if(theme == ColorTheme.Dunkel) document.documentElement.setAttribute('data-theme', ColorTheme.Dunkel);
        if(theme == ColorTheme.System) document.documentElement.setAttribute('data-theme', ColorTheme.System);
    }

    public async getTheme() : Promise<ColorTheme>
    {
        let theme = await this.db.getTheme();
        theme = theme == null ? ColorTheme.System : theme;
        return theme;
    }

    public async setCurrentTheme()
    {
        let theme = await this.getTheme();
        await this.setTheme(theme);
    }

}