import * as React from "react";
import "./alleAutorenBuecherComponent.css";
import "./shared.css";
import Buch from "../Datenmodell/Buch";
import BuchService from "../Logik/BuecherService";
import BuchError from "../Datenmodell/BuchError";
import PulseLoader from "react-spinners/PulseLoader";
import BuchComponent from './buchComponent';
import BackArrow from "./backArrowSVGComponent";
import ScrollPositionHandler from "../Logik/ScrollPositionHandler";
import ScrollPosition from "../Datenmodell/ScrollPosition";
import { faArrowUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export interface IAlleAutorenBuecherProps 
{
    buecherService: BuchService,
    scrollPositionHandler: ScrollPositionHandler;
    location: any;
}

export interface IAlleAutorenBuecherState 
{
    buecher: Array<Buch> | null,
    error: string | null,
    loading: boolean,
    showScrollUpButton: boolean
}

class AlleAutorenBuecher extends React.Component<IAlleAutorenBuecherProps, IAlleAutorenBuecherState>
{
    private autor: string | null = "";
    constructor(props: IAlleAutorenBuecherProps) 
    {
        super(props);

        this.state = 
        {
            buecher: null,
            error: null,
            loading: true,
            showScrollUpButton: false
        };
    }

    async componentDidMount() 
    {
        this.addScrollEventListener();
        this.autor = this.getAutorFromQuery();
        let buecher = new Array<Buch>();
        if(!this.autor) 
        {
            this.setState({ error: "Autor konnte nicht gefunden werden." });
        } 
        else 
        {
            buecher = await this.props.buecherService.holeAllBuecherVonAutor(this.autor) as Array<Buch>;
        }

        if (!buecher) this.setState({ error: "Für diese*n Autor*in konnten leider keine Bücher gefunden werden." });
        if (buecher instanceof BuchError) this.setState({ error: "Bücher konnten leider nicht geladen werden. Prüfe bitte deine Internetverbindung." });
        else 
        {
            buecher.sort((a, b) => (Buch.compareBuecher(a, b)));
            this.setState({ buecher: buecher, loading: false });
        }

        await this.scrollZuLetzterPosition();
    }

    async componentWillUnmount() 
    {
        window.removeEventListener("scroll", () => this.handleScroll());
        await this.props.scrollPositionHandler.speichereLetzteScrollPosition(new ScrollPosition(window.pageYOffset));
    }

    render() 
    {
        const errorMessage = this.state.error ? <div id="autorenBuecherError">{this.state.error}</div> : null;
        const buchParts = this.state.buecher?.map((buch) => <BuchComponent buch={buch} key={buch.id} showDate={true}/> );
        const emptyMessage = (!this.state.buecher || this.state.buecher.length == 0) && !this.state.loading && !this.state.error
                            ? <div id="emptyAutorenBuecher">Für diese*n Autor*in konnten leider keine Bücher gefunden werden.</div>
                            : null;
        
        return <div className="autorenBuecherWrapper">
            
            <div className="detailsHeader">
                <button type="button" id="backButtonDetails" className="backButton" onClick={() => { this.goBack() }}><BackArrow/></button>
                <h1>Alle Bücher von {this.autor}</h1>
            </div>
            
            {errorMessage}
            
            {this.state.loading && <div className="loadingWrapper"><PulseLoader color={"var(--global-white)"} loading={this.state.loading} size={30} speedMultiplier={0.6} margin={20}/></div>}
            
            {emptyMessage}

            {!this.state.loading && this.state.buecher &&
                <div className="autorenBuecher" id="autorenBuecher">
                    {buchParts}
                </div>
            }

            {this.state.showScrollUpButton && 
                <button type="button" className="scrollToTopButtonBottom" 
                    onClick={() => this.scrollToTop()}
                    onKeyUp={(event: any) => {if(event.keyCode === 13) this.scrollToTop()}}>
                        <FontAwesomeIcon icon={faArrowUp} size="1x"/> 
                        Zum Anfang 
                        <FontAwesomeIcon icon={faArrowUp} size="1x"/>
                </button>
            }
        </div>;
    }

    private goBack(): void 
    {
        window.history.back();
    }
   
    private async scrollZuLetzterPosition() 
    {
        var initialScrollPosition = (await this.props.scrollPositionHandler.getLetzteScrollPosition()).value;
        window.scrollTo(0, initialScrollPosition);
        document?.querySelector("body")?.scrollTo(0, initialScrollPosition);
    }

    private scrollToTop() 
    {
        window.scrollTo(0, 0);
        document?.querySelector("body")?.scrollTo(0, 0);
    }

    private handleScroll() 
    {
        if (window.scrollY > 1000) {
            if(!this.state.showScrollUpButton) this.setState({showScrollUpButton: true});
        } else {
            if(this.state.showScrollUpButton) this.setState({showScrollUpButton: false});
        }
    }

    private addScrollEventListener() 
    {
        window.addEventListener("scroll", () => this.handleScroll());
    }

    private getAutorFromQuery() 
    {
        const queryString = this.props.location.search;        
        let query = new URLSearchParams(queryString);
        return query.get("autor_in");
    }
}
export default AlleAutorenBuecher;