import * as React from "react";
import "./shared.css";
import "./welcome.css";
import BuchService from "../Logik/BuecherService";

export interface IWelcomeProps 
{
    buchService: BuchService,
    redirected: () => Promise<void>,
}

class Welcome extends React.Component<IWelcomeProps, {}>
{

    constructor(props: IWelcomeProps) 
    {
        super(props);    

        this.state = 
        {
           
        };
    }

    async componentDidMount() 
    {        
       await this.props.buchService.holeHeutigeBuecher();
    }   
    
    render() 
    {
        return <div className="welcome">
            <img className="startLogo" src="Logo_kl_ohneHintergrund.svg" width="55px" alt="Logo mit Vogel singend auf Büchern"/>
            <h1 className="startHeading">Willkommen beim Buchmelder!</h1>
            <p className="startText">Der Buchmelder zeigt dir jeden Tag welche Bücher neu erschienen sind. Du kannst auch gezielt nach Neuerscheinungen deiner Lieblings&shy;autor*innen filtern.</p>
            <div className="startButton" onClick={async () => await this.props.redirected()}>Jetzt losstöbern!</div>
            <img className="startBild" src="Acoyo-Bücherstapel_z.png" width="350px" alt="Bücherstapel mit aufgeschlagenem Buch daneben auf dem ein bunter Vogel sitzt."/>
        </div>;
    }
}
export default Welcome;