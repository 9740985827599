import axios, { AxiosInstance } from 'axios';

export default class AxiosInstanceFactory {

    private _axiosApiInstance: AxiosInstance;

    public constructor() {
      this._axiosApiInstance = this.createAxiosInstance();
    }

    public getCancallationSource()
    {
      return axios.CancelToken.source();
    }

    public getInstance(): AxiosInstance {
      return this._axiosApiInstance;
    }

    private createAxiosInstance(): AxiosInstance {
      const axiosApiInstance: AxiosInstance = axios.create();

      // Request interceptor for API calls
      axiosApiInstance.interceptors.request.use(
        //async 
        (config: any) => {
          //const value = await getToken();
          //const keys = JSON.parse(value);
          // config.headers = { 
          //   'Authorization': `Bearer ${keys.access_token}`,
          //   'Accept': 'application/json',
          //   'Content-Type': 'application/x-www-form-urlencoded'
          // };
          config.headers = { 
            'Accept-Encoding': 'gzip, deflate',
          };
          return config;
        },
        (error: any) => {
          Promise.reject(error)
      });

      return axiosApiInstance;
    }    
}
