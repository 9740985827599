export enum Zeitraum {
    Heute = 1,
    SiebenTage = 7,
    DreißigTage= 30
}

export function mapZeitraumStringToEnum(zeitraum: string | null): Zeitraum {
    switch (zeitraum) {
        case "1": return Zeitraum.Heute;
        case "7": return Zeitraum.SiebenTage;
        case "30": return Zeitraum.DreißigTage;
        default: return Zeitraum.Heute;
    }
        
}
