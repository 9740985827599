export default class Request 
{
    public id: number | undefined;
    public url: string;
    public body: string;
    public type: "put" | "post";

    public constructor(url: string, type: "put" | "post", body: string) 
    {
        this.url = url;
        this.body = body;
        this.type = type;
    }
}