import * as React from "react";
import "./impressum.css";
import "./shared.css";
import BackArrow from "./backArrowSVGComponent";


class ImpressumComponent extends React.Component<{}>
{
    constructor(props: {}) 
    {
        super(props);        
    }

    async componentDidMount() {}

    componentWillUnmount() {}
  
    render() 
    { 
        return <div>

                <div className="detailsHeader">
                    <button type="button" className="backButton" onClick={() => { this.goBack() }}><BackArrow/></button>
                    <h1>Impressum</h1>
                </div>

                <div className="impressum">                    
                    <p><span className="textHighlight">Acoyo Software GmbH</span>
                        <br/> Eilenburger Straße 14
                        <br/> 01309 Dresden
                        <br/>        
                        <br/> <span className="textHighlight">Geschäftsführer:</span> Christoph Lieske
                        <br/> <span className="textHighlight">Telefon:</span> +49 176 - 55561986
                        <br/> <span className="textHighlight">E-Mail:</span> info@acoyo.de
                        <br/>
                        <br/> <span className="textHighlight">Registergericht:</span> Amtsgericht Dresden
                        <br/> <span className="textHighlight">Registernummer:</span> HRB 41478
                        <br/> <span className="textHighlight">Ust-ID:</span> DE343478397
                    </p>
                </div>
            </div>;               
    }

    private goBack(): void 
    {
        window.history.back();
    }
}
export default ImpressumComponent;