import Autor from './Autor';
import {BuchDTO} from './BuchDTO';

export default class Buch{
    private _id: string;
    private _titel: string;
    private _subTitel: string;
    private _beschreibung: string;
    private _autoren: Array<Autor>;
    private _genres: Array<string>;
    private _datumVeroeffentlichung: string;
    private _bigThumbnailUrl: string;
    private _smallThumbnailUrl: string;
    private _preis: number;
    private _waehrung: string;
    private _isEbook: boolean;
    private _verlag: string;
    private _sprache: string;
    private _seitenanzahl: number;
    private _isbn: string;
    private _buecherDeLink: string;
    private _istGemerkt?: boolean;

    public constructor (buchAusApi: BuchDTO) {
        this._id = buchAusApi.id;
        this._titel = buchAusApi.titel;
        this._subTitel = buchAusApi.subTitel;
        this._beschreibung = buchAusApi.beschreibung;
        this._autoren = buchAusApi.autoren.map(as => new Autor(as.value));
        this._genres = buchAusApi.genres;
        this._datumVeroeffentlichung = buchAusApi.datumVeroeffentlichung;
        this._bigThumbnailUrl = buchAusApi.bigThumbnailUrl;
        this._smallThumbnailUrl = buchAusApi.smallThumbnailUrl;
        this._preis = buchAusApi.preis;
        this._waehrung = buchAusApi.waehrung;
        this._isEbook = buchAusApi.isEbook;
        this._verlag = buchAusApi.verlag;
        this._sprache = buchAusApi.sprache;
        this._seitenanzahl = buchAusApi.seitenanzahl;
        this._isbn = buchAusApi.isbn;
        this._buecherDeLink = buchAusApi.buecherDeLink;
        this._istGemerkt = buchAusApi.istGemerkt;
    }

    public get id(): string {
        return this._id;
    }

    public get titel(): string {
        return this._titel;
    }

    public get subTitel(): string {
        return this._subTitel;
    }

    public get beschreibung(): string {
        return this._beschreibung;
    }

    public get autoren(): Array<Autor> {
        let sortedAutoren = this._autoren.sort((a, b) => (Autor.compareAutoren(a, b)));
        return sortedAutoren;
    }

    public get genres(): Array<string> {
        return this._genres;
    }

    public get datumVeroeffentlichung(): string {
        return this._datumVeroeffentlichung;
    }

    public get bigThumbnailUrl(): string {
        return this._bigThumbnailUrl;
    }

    public get smallThumbnailUrl(): string {
        return this._smallThumbnailUrl;
    }

    public get preis(): number {
        return this._preis;
    }

    public get waehrung(): string {
        return this._waehrung;
    }

    public get isEbook(): boolean {
        return this._isEbook;
    }

    public get verlag(): string {
        return this._verlag;
    }

    public get sprache(): string {
        return this._sprache;
    }

    public get seitenanzahl(): number {
        return this._seitenanzahl;
    }

    public get isbn(): string {
        return this._isbn;
    }

    public get buecherDeLink(): string {
        return this._buecherDeLink;
    }

    public get istGemerkt(): boolean | undefined {
        return this._istGemerkt;
    }

    private static compareBuecherNachAutoren(a: Buch, b: Buch): number
    {
        let aAutoren = a.autoren;
        let bAutoren = b.autoren;
        if(!aAutoren || aAutoren.length == 0) return 1;
        if(!bAutoren || bAutoren.length == 0) return -1;
        let firstA = aAutoren[0];
        let firstB = bAutoren[0];
        if(!firstA || !firstA.name) return 1;
        if(!firstB || !firstB.name) return -1;
        var splitA = firstA.name.split(" ");
        var splitB = firstB.name.split(" ");
        var lastA = splitA[splitA.length - 1];
        var lastB = splitB[splitB.length - 1];
    
        if (lastA.toLocaleLowerCase() < lastB.toLocaleLowerCase()) return -1;
        if (lastA.toLocaleLowerCase() > lastB.toLocaleLowerCase()) return 1;
        return 0;
    }

    private static compareBuecherNachDatum(a: Buch, b: Buch): number
    {    
        if (a.datumVeroeffentlichung > b.datumVeroeffentlichung) return -1;
        if (a.datumVeroeffentlichung < b.datumVeroeffentlichung) return 1;
        return 0;
    }

    private static compareBuecherNachTitel(a: Buch, b: Buch): number
    {
        if (a.titel < b.titel) return -1;
        if (a.titel > b.titel) return 1;
        return 0;
    }

    private static compareBuecherNachIsbn(a: Buch, b: Buch): number
    {
        if (a.isbn < b.isbn) return -1;
        if (a.isbn > b.isbn) return 1;
        return 0;
    }

    public static compareBuecher(a: Buch, b: Buch): number
    {
        var datumCompare = this.compareBuecherNachDatum(a, b);
        if(datumCompare != 0) return datumCompare;
        
        var autorenCompare = this.compareBuecherNachAutoren(a, b);
        if(autorenCompare != 0) return autorenCompare;

        var titelCompare = this.compareBuecherNachTitel(a, b);
        if(titelCompare != 0) return titelCompare;

        return this.compareBuecherNachIsbn(a, b);
    }
}
