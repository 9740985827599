import * as React from "react";
import "./headerComponent.css";
import "./shared.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCog, faExclamationTriangle, faTimes } from '@fortawesome/free-solid-svg-icons';
import {Link} from "react-router-dom";
import {Snackbar} from "@material-ui/core";


export interface IHeaderComponentProps
{
    showReload: boolean;
}

export interface IHeaderComponentState 
{    
    windowWidth: number;
    offline: boolean;
}

const mobileBreak = 670;
const mobileBreak2 = 430;

class HeaderComponent extends React.Component<IHeaderComponentProps, IHeaderComponentState>
{
    constructor(props: any) 
    {
        super(props);
        
        this.state = 
        {
            windowWidth: window.innerWidth,
            offline: false,
        };
    }

    componentDidMount() 
    {       
        window.addEventListener("resize", () => this.handleResize());
        window.addEventListener("offline", () => {
            this.setOfflineStatus(true);
          });
        window.addEventListener("online", () => {
            this.setOfflineStatus(false);
        });
    }

    componentDidUpdate()
    {
        
    }

    componentWillUnmount() 
    {
        window.removeEventListener("resize", () => this.handleResize());
    }
  
    render() 
    {   
        let desktopHeader = <div className="desktopHeader">
                                <Link to={"/"}><img src="Logo_kl_ohneHintergrund.svg" height="80px" width="85px" alt="Logo"/></Link>
                                <div className="headerCenterPart">
                                <Link to={"/"}><h1>Buchmelder</h1></Link>
                                    <h2>Deutschsprachige Neuerscheinungen auf einen Blick.</h2>
                                </div>
                                <Link className="cog" to={"/Konfiguration"}><FontAwesomeIcon icon={faCog} size="3x"/></Link>
                            </div>;

        let mobileHeader = <div className="mobileHeader">
                                <div className="upperPart">
                                    <Link to={"/"}><img src="Logo_kl_ohneHintergrund.svg" height="40px" width="45px" alt="Logo"/></Link>
                                    <Link to={"/"}><h1 className={this.state.windowWidth < mobileBreak2 ? "mobileHeadingSmaller" : "mobileHeading"}>Buchmelder</h1></Link>
                                    <Link className="cog" to={"/Konfiguration"}><FontAwesomeIcon icon={faCog} size="2x"/></Link>
                                </div>
                                <h2>Deutschsprachige Neuerscheinungen auf einen Blick.</h2>
                            </div>;
        let headerToRender = this.state.windowWidth < mobileBreak ? mobileHeader : desktopHeader;
        
        return <div className="globalHeader">   
                    <Snackbar open={this.state.offline} 
                            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                            autoHideDuration={8000}
                            className="offlineSnackbar"
                            onClose={() => this.setOfflineStatus(false)}>
                                <div className="offlineSnackbarInnerWrapper">
                                    
                                    <div className="offlineSnackbarContent">
                                        <FontAwesomeIcon icon={faExclamationTriangle} size="2x"/>
                                        <span>Du bist offline. Die App wird dir nur Daten anzeigen, die du online bereits geladen hattest.</span>
                                        <button
                                        type="button"
                                        aria-label="schließen"                                        
                                        onClick={() => this.setOfflineStatus(false)}
                                        >
                                            <FontAwesomeIcon icon={faTimes} size="2x"/>
                                        </button>
                                    </div>                                    
                                </div>  
                    </Snackbar>            
                    <Snackbar open={this.props.showReload} 
                            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                            autoHideDuration={6000}
                            className="reloadSnackbar">
                                <div className="reloadSnackbarInnerWrapper">                                    
                                    <div className="reloadSnackbarContent">
                                        <span>Update gefunden. Buchmelder wird aktualisiert ...</span>                                       
                                    </div>                                    
                                </div>                           
                    </Snackbar>    
                    { headerToRender }
                </div>;
    }

    private handleResize() 
    {
        this.setState({ windowWidth: window.innerWidth });
    }

    private setOfflineStatus(value: boolean) 
    {
        this.setState({offline: value});
    }

}
export default HeaderComponent;