import * as React from "react";
import "./autorAutocompleteSelect.css";
import "./shared.css";
import Autor from "../Datenmodell/Autor";
import VorschlagsAutorService from "../Logik/VorschlagsAutorenService";
import AsyncSelect from 'react-select/async';

export interface IAutorAutocompleteSelectProps 
{    
    vorschlagsAutorService: VorschlagsAutorService;
    hasChanged: (autor: Autor) => void;
}

export interface IAutorAutocompleteSelectState 
{
    currentAutor: string | null;
    loading: boolean;
}


class AutorAutocompleteSelect extends React.Component<IAutorAutocompleteSelectProps, IAutorAutocompleteSelectState>
{
    constructor(props: IAutorAutocompleteSelectProps) 
    {
        super(props);
        
        this.state = 
        {
            currentAutor: null,
            loading: true
        };
    }

    private loadOptions = async (inputValue): Promise<Array<string>> => {
                  return await (await this.props.vorschlagsAutorService.getFilteredAutoren(inputValue)).map(a => a.name);
              };

    componentDidMount() 
    {
        this.setState({currentAutor: null});
    }

    componentWillUnmount() {}
  
    render() 
    {         
        
        return <div>
            <AsyncSelect
                id="autorSelect"           
                value={[this.state.currentAutor]}    
                onInputChange={(value) => {  
                    if(value)
                    {
                        let autor = new Autor(value);
                        this.setState({currentAutor: value});
                        this.props.hasChanged(autor);
                    }                    
                }}
                onChange={(value) => {
                    let autor = new Autor(value);
                    this.setState({currentAutor: value});
                    this.props.hasChanged(autor);
                }}
                isSearchable={true}
                isClearable={true}
                loading={this.state.loading}
                defaultOptions
                autoFocus={false}
                loadOptions={this.loadOptions}
                getOptionSelected={(option) => option}
                getOptionValue={(option) => option}
                getOptionLabel={(option) => option}
                placeholder="Vorname Nachname"
                loadingMessage={() => "Suche ..."}
                noOptionsMessage={() => null}                
                theme={theme => ({
                    ...theme,
                    colors: {
                        ...theme.colors,
                        primary75: 'var(--global-light-primary-color)',
                        primary50: 'var(--global-lighter-primary-color)',
                        primary25: 'var(--global-lighter-primary-color)',
                        primary: 'var(--global-primary-color)',
                    },
                    })}
            />
        </div>                   
    }


    public resetValue() : void
    {
        this.setState({currentAutor: null});
    }
}
export default AutorAutocompleteSelect;