import * as React from "react";
import "./autorDeleteComponent.css";
import "./shared.css";
import Autor from "../Datenmodell/Autor";

export interface IDeletePopupContentComponentProps {    
    autor: Autor | null,
    delete: () => void,
    abortDelete: () => void
}


class DeletePopupContentComponent extends React.Component<IDeletePopupContentComponentProps>
{
    constructor(props: IDeletePopupContentComponentProps) 
    {
        super(props);        
    }

    async componentDidMount() {}

    componentWillUnmount() {}
  
    render() 
    { 
        return <div className="deleteModalInnerWrapper">
                    <label className="autorenLabel">Willst du {this.props.autor?.name} aus deinem Filter entfernen?</label> 
                    <div className="deleteButtons">
                        <button type="button" className="bigAddButton" autoFocus onClick={() => this.props.delete()} onKeyUp={(event: any) => {if(event.keyCode === 13) this.props.delete()}}>Löschen</button>
                        <button type="button" className="secondButton" onClick={() => this.props.abortDelete()} onKeyUp={(event: any) => {if(event.keyCode === 13) this.props.abortDelete()}}>Abbrechen</button>                
                    </div> 
                </div>;               
    }
}
export default DeletePopupContentComponent;