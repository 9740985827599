import Nutzer from '../Datenmodell/Nutzer';
import BuchmelderDb from './BuchmelderDb';
import ServiceClient from './ServiceClient';
import {NutzerDTO} from '../Datenmodell/NutzerDTO';
import GemerktesBuch from '../Datenmodell/GemerktesBuch';
import { ExtendedPushSubscription } from '../Datenmodell/ExtendedPushSubscription';

export default class NutzerService 
{
    private serviceClient: ServiceClient;
    private db: BuchmelderDb;

    public constructor(externalServiceClient: ServiceClient, buecherDb: BuchmelderDb) 
    {
        this.serviceClient = externalServiceClient;
        this.db = buecherDb;
    }

    public async registriereNutzer(): Promise<Nutzer> 
    {
        let nutzerAusDb: Nutzer | null = await this.db.getNutzer();
        if(!nutzerAusDb) 
        {
            let nutzerId = await this.serviceClient.registriereNutzerBeiService();
            let nutzer : NutzerDTO = {id: nutzerId};
            await this.db.speichereNutzer(nutzer);

            await this.restoreNutzer(nutzerId);

            return new Nutzer(nutzerId);
        }
        return nutzerAusDb;
    }

    public async getNutzer(): Promise<Nutzer | null> 
    {
        let nuzterVonDb: NutzerDTO | null = await this.db.getNutzer();
        if(nuzterVonDb)
        {
            return new Nutzer(nuzterVonDb.id);   
        }
        return null;
    }

    private async restoreNutzer(nutzerId: string)
    {   
        if (('serviceWorker' in navigator))
        {
            const registration = await navigator.serviceWorker.ready;
            let browserSub = await registration.pushManager.getSubscription();
            if(browserSub) await this.serviceClient.subscribeToBenachrichtigungen(nutzerId, new ExtendedPushSubscription(browserSub, "alle"));
        }

        let dbSubDTO = await this.db.getSubscription();
        if(dbSubDTO)
        {
            if(dbSubDTO.isAutorenFilterActivated) await this.db.setzeAutorenFilterFuerSubscriptionToActivated();
        }
        else
        {
            let status = await this.serviceClient.istAutorenFilterBereitsAktiv(nutzerId);
            if(status) await this.db.setzeAutorenFilterFuerSubscriptionToActivated();

        }        

        var autoren = await this.db.autorenFilter();
        if(autoren && autoren.length == 0) 
        {
            let autorenVonService = await this.serviceClient.holeAutorenFilter(nutzerId);
            await this.db.speichereAutoren(autorenVonService);
        }
        else
        {
            if(autoren && autoren.length > 0) this.serviceClient.sendeNeuenAutorenFilter(nutzerId, autoren.map(a => a.value));  
        }

        var gemerkteBuecher = await this.db.gemerkteBuecher();
        if(gemerkteBuecher && gemerkteBuecher.length == 0) 
        {
            let gemerkteBuecherVonService = await this.serviceClient.holeMerkliste(nutzerId);
            let buchIdsZumSpeichern = gemerkteBuecherVonService.map(bi => new GemerktesBuch(bi));
            await this.db.speichereGemerkteBuecher(buchIdsZumSpeichern);
        }
        else
        {
            if(gemerkteBuecher && gemerkteBuecher.length > 0) this.serviceClient.sendeNeueMerkliste(nutzerId, gemerkteBuecher.map(gm => gm.id));  
        }
    }

}