import BuchmelderDb from './BuchmelderDb';
import ServiceClient from './ServiceClient';
import VorschlagsAutor from '../Datenmodell/VorschlagsAutor';
import { VorschlagsAutorDTO } from '../Datenmodell/VorschlagsAutorDTO';

export default class VorschlagsAutorenService 
{
    private serviceClient: ServiceClient;
    private db: BuchmelderDb;

    public constructor(externalServiceClient: ServiceClient, buecherDb: BuchmelderDb) 
    {
        this.serviceClient = externalServiceClient;
        this.db = buecherDb;
    }

    public async getFilteredAutoren(filterString: string): Promise<Array<VorschlagsAutor>> 
    { 
        if(!filterString) filterString = "";
        //let autorenVonDb: Array<VorschlagsAutorDTO> = await this.db.getAlleVorschlagsAutoren();
        let autorenVonDb: Array<VorschlagsAutorDTO> = JSON.parse(localStorage.getItem("vorschlagsAutoren") ?? "null");
        if(!autorenVonDb || autorenVonDb.length == 0)
        {
            try {
                await this.ladeAutoren();
                autorenVonDb = JSON.parse(localStorage.getItem("vorschlagsAutoren") ?? "null");//await this.db.getAlleVorschlagsAutoren();                
            } catch(ex) {
                console.error("Fehler beim Abrufen der Vorschlagsautoren.");
            }
        }
        let autorenVorschlaege =  autorenVonDb.map(adto => new VorschlagsAutor(adto.id, adto.name));
        let gefiltert = autorenVorschlaege.filter(item => item.name.toLowerCase().includes(filterString.toLowerCase()));
        gefiltert.sort((a, b) => (VorschlagsAutor.compareVorschlagsAutoren(a, b)));
        gefiltert.sort((a, b) => (VorschlagsAutor.compareVorschlagsAutorenBySpecialCharacter(a, b)));
        return gefiltert.slice(0, 500);
    }

    public async ladeAutoren(): Promise<void> 
    {       
        let autorenVonService = await this.serviceClient.holeAutorenVorschläge();
        //await this.db.speichereAutorenVorschläge(autorenVonService); // dauert zu lange
        localStorage.setItem("vorschlagsAutoren", JSON.stringify(autorenVonService));
    }
}