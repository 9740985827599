import AnzeigeNachricht from './AnzeigeNachricht';
import NotificationOptions from 'typescript';
import {baseUrl} from "../Logik/ServiceClient";
import moment from 'moment';

const publicVapidKey = "BMYQKGQyhxXHZvqnr9Ie6fEGOHIf8lXdzSWnqeQhKdAZ4qKN3VmtfkpWjcQIHCTDFstdiz4uPcioc2Jdqs1qgtI";
const urlBase64ToUint8Array = (base64String: string) => 
{
      const padding = '='.repeat((4 - base64String.length % 4) % 4);
      const base64 = (base64String + padding)
        .replace(/\-/g, '+')
        .replace(/_/g, '/');
        const rawData = window.atob(base64);
      const outputArray = new Uint8Array(rawData.length);

      for (let i = 0; i < rawData.length; ++i) {
          outputArray[i] = rawData.charCodeAt(i);
      }
      return outputArray;
  };

const pwaOpeningUrlAlleBuecher = "https://buchmelder.de";
const pwaOpeningUrlAutoren = pwaOpeningUrlAlleBuecher + "/#/?show=nachautoren&zeitraum=1";
const benachrichtigungsUrl = baseUrl + "/Benachrichtigung";

export default class ServiceWorkerService 
{
    public static getApplicationServerKey(): Uint8Array 
    {
        return urlBase64ToUint8Array(publicVapidKey);
    }

    public static getSubscriptionActivationUrl(nutzer: string): string 
    {
        return baseUrl + `/Nutzer/${nutzer}/Benachrichtigung/Aktivieren`;
    }

    public static getPwaOpenAlleBuecherUrl(): string 
    {
        return pwaOpeningUrlAlleBuecher;
    }

    public static getPwaBuecherNachAutorinnenUrl(): string 
    {
        return pwaOpeningUrlAutoren;
    }

    public static getPwaHost(): string 
    {
        const url = new URL(pwaOpeningUrlAlleBuecher);
        return url.hostname;
    }

    public static getBenachrichtigungsUrl(id: string): string 
    {
        return benachrichtigungsUrl + "/" + id;
    }

    public static baueNotificationOptions(nachricht: AnzeigeNachricht): NotificationOptions 
    {        
        const options = {
          body: nachricht.benachrichtigungsTyp == 1 || nachricht.benachrichtigungsTyp == 2 ? nachricht.text : ServiceWorkerService.baueTextFuerNotification(nachricht),
          icon: nachricht.image,
          data: nachricht.benachrichtigungsTyp
        };
        return options
    }

    private static baueTextFuerNotification(nachricht: AnzeigeNachricht): string 
    {
        let todayString = moment().format("DD.MM.yyyy");
        if(nachricht.datum === todayString  || nachricht.buchAnzahl < 0)
        {
            return nachricht.text;
        }
        else
        {
            let text = nachricht.buchAnzahl > 1 
                    ? "Am " + nachricht.datum + " sind " + nachricht.buchAnzahl + " Bücher erschienen." 
                    : "Am " + nachricht.datum + " ist " + nachricht.buchAnzahl + " Buch erschienen.";
            return text;
        }
    }
}