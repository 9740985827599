export default class Autor {
    private _name: string;

    public constructor (name: string) {
        this._name = name;
    }

    public get name(): string {
        return this._name;
    }

    public static compareAutoren(a: Autor, b: Autor) 
    { 
        if(!a || !b || !a.name || !b.name) return -1;
        var splitA = a.name.split(" ");
        var splitB = b.name.split(" ");
        var lastA = splitA[splitA.length - 1];
        var lastB = splitB[splitB.length - 1];
    
        if (lastA.toLocaleLowerCase() < lastB.toLocaleLowerCase()) return -1;
        if (lastA.toLocaleLowerCase() > lastB.toLocaleLowerCase()) return 1;
        return 0;
    }
}