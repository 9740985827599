export default class VorschlagsAutor 
{
    private _id: string;
    private _name: string;

    public constructor (id: string, name: string) 
    {
        this._id = id;
        this._name = name;
    }

    public get id(): string 
    {
        return this._id;
    }

    public get name(): string 
    {
        return this._name;
    }

    public static compareVorschlagsAutoren(a: VorschlagsAutor, b: VorschlagsAutor) 
    { 
        if(!a || !b || !a.name || !b.name) return -1;
        var splitA = a.name.trim().split(" ");
        var splitB = b.name.trim().split(" ");
        var lastA = splitA[splitA.length - 1];
        var lastB = splitB[splitB.length - 1];
        var firstA = splitA[0];
        var firstB = splitB[0];
    
        if(lastA == lastB) 
        {
            if (firstA.toLocaleLowerCase() < firstB.toLocaleLowerCase()) return -1;
            if (firstA.toLocaleLowerCase() > firstB.toLocaleLowerCase()) return 1;
        }
        else
        {
            if (lastA.toLocaleLowerCase() < lastB.toLocaleLowerCase()) return -1;
            if (lastA.toLocaleLowerCase() > lastB.toLocaleLowerCase()) return 1;
        }
       
        return 0;
    }

    public static compareVorschlagsAutorenBySpecialCharacter(a: VorschlagsAutor, b: VorschlagsAutor) 
    { 
        if(!a || !b || !a.name || !b.name) return -1;
        var splitA = a.name.trim().split(" ");
        var splitB = b.name.trim().split(" ");
        var lastA = splitA[splitA.length - 1];
        var lastB = splitB[splitB.length - 1];
        var firstA = splitA[0];
        var firstB = splitB[0];
    
        if(lastA == lastB) 
        {
            var aIsAlphabetical = firstA.localeCompare("a") >= 0;
            var bIsAlphabetical = firstB.localeCompare("a") >= 0;
            if (!aIsAlphabetical && bIsAlphabetical)
                return 1;
            if (aIsAlphabetical && !bIsAlphabetical)
                return -1;
            return firstA.localeCompare(firstB);
        }
        else
        {
            aIsAlphabetical = lastA.localeCompare("a") >= 0;
            bIsAlphabetical = lastB.localeCompare("a") >= 0;
            if (!aIsAlphabetical && bIsAlphabetical)
                return 1;
            if (aIsAlphabetical && !bIsAlphabetical)
                return -1;
            return lastA.localeCompare(lastB);
        }
    }
}