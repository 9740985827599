import {ShowType, mapStringToEnum} from "../Datenmodell/ShowType";
import {Zeitraum, mapZeitraumStringToEnum} from '../Datenmodell/Zeitraum';

const regExp = new RegExp("\\?show=(.*)&zeitraum=(.*)");
const regExpBuch = new RegExp("\\?id=.*");

export default class AmazonService 
{
    public static extrahiereShowTypeAusUrl(url: string): ShowType 
    {
        let paramPart = url.match(regExp);
        let show = paramPart ? paramPart[1] : "alle";
        return mapStringToEnum(show);
    }

    public static extrahiereZeitraumAusUrl(url: string): Zeitraum 
    {
        let paramPart = url.match(regExp);
        let zeitraum = paramPart ? paramPart[2] : "1";
        return mapZeitraumStringToEnum(zeitraum);
    }

    public static extrahiereBuchIdAusUrl(url: string): string | null {
        let idPart = url.match(regExpBuch);
        let id = idPart ? idPart[0].replace("?id=", "") : "";
        return id;
    }
}