
import * as React from "react";

class BackArrow extends React.Component {
    render() {
        return (
            <svg version="1.1" id="Ebene_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" aria-hidden="true"
                    width="25px" viewBox="0 0 16.667 13.125" enableBackground="new 0 0 16.667 13.125">
                <line fill="none" stroke="var(--global-text-color)" strokeLinecap="round" strokeMiterlimit="10" x1="0.694" y1="6.606" x2="6.627" y2="12.54"/>
                <line fill="none" stroke="var(--global-text-color)" strokeLinecap="round" strokeMiterlimit="10" x1="0.694" y1="6.606" x2="6.627" y2="0.673"/>
                <line fill="none" stroke="var(--global-text-color)" strokeLinecap="round" strokeMiterlimit="10" x1="0.694" y1="6.606" x2="16" y2="6.606"/>
            </svg>            
        )
    }
}

export default BackArrow;