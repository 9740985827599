import * as React from "react";
import "./ueberDieApp.css";
import "./shared.css";
import {Link} from "react-router-dom";
import BackArrow from "./backArrowSVGComponent";


class ImpressumComponent extends React.Component<{}>
{
    constructor(props: {}) 
    {
        super(props);        
    }

    async componentDidMount() {}

    componentWillUnmount() {}
  
    render() 
    { 
        return <div>
                <div className="detailsHeader">
                    <button type="button" className="backButton" onClick={() => { this.goBack() }}><BackArrow/></button>
                    <h1>Über die App</h1>
                </div>
                <div className="ueberDieApp">                    
                    <p>Die Buchmelder-App ist eine <span className="textHighlight">Progressive Web App</span> (PWA) entwickelt von der <Link to={"/UeberAcoyo"} className="textHighlight">Acoyo Software GmbH</Link>. Sie ist als normale Webseite über einen Webbrowser aufrufbar. Zusätzlich kannst du die PWA aber auch wie eine normale App <span className="textHighlight">auf deinem Gerät installieren</span>. Dadurch wird ein <span className="textHighlight">Link auf dem Homescreen</span> erstellt. Über diesen kannst du die PWA schnell öffnen und sie fühlt sich an, wie eine native App. </p>

<p>Durch die PWA ist es auch möglich dich aktiv über neue Bücher zu benachrichtigen. Dafür musst du die App auf dem Gerät installieren, auf dem du die <span className="textHighlight">Benachrichtigungen</span> erhalten möchtest.</p>

<p>Die App bietet aktuell noch <span className="textHighlight">keine Synchronisation über Geräte hinweg</span> an. Diese Funktion ist aber in Planung. Solltest du deinen Autorenfilter auf verschiedenen Geräten nutzen wollen, musst du ihn auf jedem Gerät neu anlegen.
<br/>Die App <span className="textHighlight">speichert sich die Daten im Browser-Cache</span> ab. Dadurch ist sie auch <span className="textHighlight">offline nutzbar</span>, mit allen Daten, die du bereits online zuvor abrufen konntest. Wenn du deinen Browser-Cache leerst, musst du deinen Autorenfilter wieder neu eingeben.</p>

<p>Im Hintergrund kommuniziert unsere App mit einem Webservice, der sich um die Bereitstellung der Bücher kümmert. Dafür wird die <a className="textHighlight" href="https://developers.google.com/books" target="_blank" rel="noopener noreferrer">Google Books API</a> verwendet. Diese API ist die einzige Quelle für die neu erschienen Bücher. Leider kann es bei den Daten der API auch mal zu Ungenauigkeiten kommen, so dass ein Buch zum Beispiel gar nicht bei uns auftaucht. Darüber hinaus bestehen keine Verbindungen zwischen uns und Google. Es werden auch keine Daten an Google gesendet.</p>
<p>Unsere App selbst speichert deinen Autoren-Filter anonym ab und trackt keine Daten über dich.</p>
<p>Updates für die App werden nach richtigem Schließen (Browser nicht mehr im Hintergrund offen) und wieder Öffnen der App automatisch geladen. Es erscheint ein Hinweis. Danach wird die Seite einmal neu geladen.</p>
                </div>
        </div>;               
    }
    
    private goBack(): void 
    {
        window.history.back();
    }
}
export default ImpressumComponent;