import Dexie from 'dexie';
import {BuchDTO} from '../Datenmodell/BuchDTO';
import BuchDbDTO from '../Datenmodell/BuchDbDTO';
import Nutzer from '../Datenmodell/Nutzer';
import SubscriptionDTO from '../Datenmodell/SubscriptionDTO';
import {AutorDTO} from '../Datenmodell/AutorDTO';
import {NutzerDTO} from '../Datenmodell/NutzerDTO';
import AbgerufenerTag from '../Datenmodell/AbgerufenerTag';
import AbgerufenerAutor from '../Datenmodell/AbgerufenerAutor';
import GemerktesBuch from '../Datenmodell/GemerktesBuch';
import Request from '../Datenmodell/Request';
import moment from 'moment';
import { VorschlagsAutorDTO } from '../Datenmodell/VorschlagsAutorDTO';
import SelectedColorTheme from '../Datenmodell/SelectedColorTheme';
import { ColorTheme } from '../Datenmodell/ColorTheme';

export default class BuchmelderDb extends Dexie
{
    buecher!: Dexie.Table<BuchDbDTO, string>;
    nutzer!: Dexie.Table<NutzerDTO, string>;
    autoren!: Dexie.Table<AutorDTO, string>;
    abgerufeneTage!: Dexie.Table<AbgerufenerTag, string>;
    subscription!: Dexie.Table<SubscriptionDTO, string>;
    requests!: Dexie.Table<Request, number>;
    vorschlagsAutoren!: Dexie.Table<VorschlagsAutorDTO, string>;
    merkliste!: Dexie.Table<GemerktesBuch, string>;
    selectedColorTheme!: Dexie.Table<SelectedColorTheme, string>;
    abgerufeneAutoren!: Dexie.Table<AbgerufenerAutor, string>;

    public constructor (database?: any) 
    {
        super("Acoyo_Buchmelder_BuecherDb");
        const db = this;
        if(database) db.backendDB = database;
        try 
        {            
            db.version(3).stores(
                {
                    buecher: 'id, titel, subTitel, beschreibung, autoren, *genres, datumVeroeffentlichung, sprache',
                    nutzer: 'id',
                    autoren: 'value',
                    abgerufeneTage: 'datum',
                    subscription: 'key, isAutorenFilterActivated',
                    requests: '++id, url, type, body',
                    vorschlagsAutoren: 'id, name',
                    merkliste: 'id',
                    selectedColorTheme: 'theme'
                }
            );

            db.version(4).stores(
                {
                    buecher: 'id, titel, subTitel, beschreibung, *autorenForFilter, autoren, *genres, datumVeroeffentlichung, sprache',
                    nutzer: 'id',
                    autoren: 'value',
                    abgerufeneTage: 'datum',
                    subscription: 'key, isAutorenFilterActivated',
                    requests: '++id, url, type, body',
                    vorschlagsAutoren: 'id, name',
                    merkliste: 'id',
                    selectedColorTheme: 'theme',
                    abgerufeneAutoren: 'name, datum',
                }).upgrade (trans => 
                    {
                        return trans.table("buecher").toCollection().modify((buch: BuchDbDTO) => 
                            {
                                buch.autorenForFilter = buch.autoren.map(a => a.value);
                            }
                    );
            });
        } 
        catch(ex) 
        {
            console.error("Fehler beim Erzeugen des IndexedDb Stores: " + ex);
        }        
    }

    public async buecherVonHeute(): Promise<Array<BuchDTO>> 
    {
        const date = moment().format('YYYY-MM-DD');
        const buecher = await this.buecher.where('datumVeroeffentlichung').equals(date).toArray();        
        if(buecher) 
        {
            return buecher;
        } 
        else 
        {
            return new Array<BuchDTO>();
        }
    }

    public async buecherFuerZeitraum(tage: number, heute: string): Promise<Array<BuchDTO>> 
    {
        try 
        {
            const enddatum = moment(heute);
            const startdatum = enddatum.subtract(tage, "days").format('YYYY-MM-DD');
            const buecher = await this.buecher.where('datumVeroeffentlichung').between(startdatum, heute, true, true).toArray();
            if(buecher) 
            {
                return buecher;
            } 
            else 
            {
                return new Array<BuchDTO>();
            }
        } 
        catch(ex) 
        {
            return new Array<BuchDTO>();
        }
    }

    public async buecherFuerIds(buchIds: Array<string>): Promise<Array<BuchDTO>> 
    {
        const buecher = await this.buecher.where('id').anyOf(buchIds).toArray();
        if(buecher) 
        {
            return buecher;
        } 
        else 
        {
            return new Array<BuchDTO>();
        }
    }


    public async buch(buchId: string): Promise<BuchDTO | null> 
    {
        const buch = await this.buecher.get(buchId);
        return buch ?? null;
    }

    public async getNutzer(): Promise<Nutzer | null> 
    {
        const count = await this.nutzer.count();
        if(count > 0) 
        {
            const nutzer = await this.nutzer.toArray(); 
            if(nutzer && nutzer.length > 0) 
            {
                return new Nutzer(nutzer[0].id);
            }
        }
        return null; 
    }

    public async autorenFilter(): Promise<AutorDTO[]> 
    {
        const autoren = await this.autoren.toArray();
        if(autoren) 
        {
            return autoren;
        } 
        else 
        {
            return new Array<AutorDTO>();
        }        
    }

    public async speichereBuecher(buecher: Array<BuchDbDTO>): Promise<void> 
    {        
        await this.buecher.bulkPut(buecher);        
    }

    public async speichereNutzer(nutzer: NutzerDTO): Promise<void> 
    {
        const nArray = await this.nutzer.toArray();
        for (const element of nArray) 
        {
            await this.nutzer.delete(element.id);
        }
        await this.nutzer.add(nutzer);
    }

    public async gemerkteBuecher(): Promise<Array<GemerktesBuch>> 
    {
        const buchIds = await this.merkliste.toArray();
        if(buchIds) 
        {
            return buchIds;
        } 
        else 
        {
            return new Array<GemerktesBuch>();
        }        
    }

    public async speichereGemerktesBuch(buchId: GemerktesBuch): Promise<void> 
    {
        if(buchId) await this.merkliste.put(buchId);
    }

    public async speichereGemerkteBuecher(buchIds: Array<GemerktesBuch>): Promise<void> 
    {
        if(buchIds && buchIds.length > 0) await this.merkliste.bulkPut(buchIds);
    }

    public async flaggeGemerktesBuch(buchId: GemerktesBuch)
    {
        if(buchId) await this.buecher.update(buchId.id, {istGemerkt: true});
    }

    public async entflaggeGemerktesBuch(buchId: string)
    {
        if(buchId) await this.buecher.update(buchId, {istGemerkt: false});
    }

    public async loescheGemerktesBuch(buchId: string): Promise<void> 
    {
        if(buchId) await this.merkliste.delete(buchId);
    }

    public async speichereAutor(autor: AutorDTO): Promise<void> 
    {
        if(autor) await this.autoren.put(autor);
    }

    public async speichereAutoren(autoren: Array<AutorDTO>): Promise<void> 
    {        
        if(autoren && autoren.length > 0) await this.autoren.bulkPut(autoren);
    }

    public async loescheAutor(autor: AutorDTO): Promise<void> 
    {
        if(autor) await this.autoren.delete(autor.value);
    }

    public async anDiesemTagBuecherAbgerufen(datum: AbgerufenerTag) 
    {
        await this.abgerufeneTage.put(datum);
    }

    public async anDiesenTagenBuecherAbgerufen(tage: number, heute: string) 
    {
        let days: Array<AbgerufenerTag> = this.createDaysArray(heute, tage);
        await this.abgerufeneTage.bulkPut(days);
    }

    public async wurdeAnAllDiesenTagenAbgerufen(tage: number, heute: string): Promise<boolean> 
    {
        let days: Array<AbgerufenerTag> = this.createDaysArray(heute, tage);
        let matchingDays = await this.abgerufeneTage.where("datum").anyOf(days.map(d => d.datum)).toArray();
        return matchingDays.length == tage + 1;
    }

    public async wurdeDieserAutorSchonAbgerufen(autor: string): Promise<boolean> 
    {
        let matchingAutor = await this.abgerufeneAutoren.where("name").equals(autor).toArray();
        return matchingAutor.length > 0;
    }

    public async diesenAutorAbgerufen(autor: string) 
    {
        const heute = moment().format('YYYY-MM-DD');
        var abgerufenerAutor = new AbgerufenerAutor(heute, autor);
        await this.abgerufeneAutoren.put(abgerufenerAutor);
    }

    public async alleBuecherVonAutor(autor: string): Promise<Array<BuchDbDTO>>
    {
        const buecher = await this.buecher.where('autorenForFilter').equalsIgnoreCase(autor).toArray();
        if(buecher) 
        {
            return buecher;
        } 
        else 
        {
            return new Array<BuchDbDTO>();
        }
    }

    public async getSubscription(): Promise<SubscriptionDTO>
    {
        let sub = await this.subscription.get(SubscriptionDTO.key);
        if(!sub) 
        {
            sub = new SubscriptionDTO();
            await this.subscription.put(sub);
        }
        return sub;
    }

    public async setzeAutorenFilterFuerSubscriptionToActivated(): Promise<void>
    {
        let sub = new SubscriptionDTO();
        sub.isAutorenFilterActivated = true;
        await this.subscription.put(sub);
    }

    public async setzeAutorenFilterFuerSubscriptionToDeactivated(): Promise<void>
    {
        let sub = await this.subscription.get(SubscriptionDTO.key);
        if(!sub) sub = new SubscriptionDTO();
        sub.isAutorenFilterActivated = false;
        await this.subscription.put(sub);
    }

    public async speicherRequest(request: Request): Promise<void>
    {
        await this.requests.add(request);
    }

    public async holeRequests(): Promise<Array<Request>>
    {
        return await this.requests.toArray();
    }

    public async loescheRequest(request: Request): Promise<void>
    {
        if(request && request.id) await this.requests.delete(request.id);
    }
    
    public async getAlleVorschlagsAutoren(): Promise<Array<VorschlagsAutorDTO>>
    {
        return await this.vorschlagsAutoren.toArray();
    }

    public async speichereAutorenVorschläge(autoren: Array<VorschlagsAutorDTO>): Promise<void>
    {        
        await this.vorschlagsAutoren.bulkPut(autoren);       
    }

    public async removeOldData()
    {
        var vor30Tagen = moment().subtract(31, "days").format('YYYY-MM-DD');
        let abgerufeneAutoren = (await this.abgerufeneAutoren.toArray()).map(a => a.name);
        await this.buecher.where('datumVeroeffentlichung').below(vor30Tagen).and(b => !b.istGemerkt && !b.autoren.some(a => abgerufeneAutoren.includes(a.value))).delete();
        await this.abgerufeneTage.where('datum').below(vor30Tagen).delete();
    }

    public async getTheme(): Promise<ColorTheme | null> 
    {
        const count = await this.selectedColorTheme.count();
        if(count > 0) 
        {
            const selectedColorTheme = await this.selectedColorTheme.toArray(); 
            if(selectedColorTheme && selectedColorTheme.length > 0) 
            {
                return selectedColorTheme[0].theme;
            }
        }
        return null; 
    }

    public async speichereTheme(theme: ColorTheme): Promise<void> 
    {
        await this.selectedColorTheme.clear();
        await this.selectedColorTheme.add(new SelectedColorTheme(theme));
    }

    private createDaysArray(heute: string, tage: number): Array<AbgerufenerTag> 
    {
        let days: Array<AbgerufenerTag> = new Array<AbgerufenerTag>();
        let heutigerTag = moment(heute);
        days.push(new AbgerufenerTag(heute));
        for (let i = 0; i < tage; i++) 
        {
            days.push(new AbgerufenerTag(heutigerTag.subtract(1, "days").format('YYYY-MM-DD')));
        }
        return days;
    }
}
