import Buch from '../Datenmodell/Buch';
import {BuchDTO} from '../Datenmodell/BuchDTO';
import {AutorDTO} from '../Datenmodell/AutorDTO';
import BuchmelderDb from './BuchmelderDb';
import ServiceClient from './ServiceClient';
import moment from 'moment';
import AbgerufenerTag from '../Datenmodell/AbgerufenerTag';
import BuchError from '../Datenmodell/BuchError';
import BuchDbDTO from '../Datenmodell/BuchDbDTO';

export default class BuecherService 
{
    private _serviceClient: ServiceClient;
    private _db: BuchmelderDb;

    public constructor(externalServiceClient: ServiceClient, buecherDb: BuchmelderDb)
    {
        this._serviceClient = externalServiceClient;
        this._db = buecherDb;
    }

    public async holeHeutigeBuecher(): Promise<Array<Buch> | BuchError>
    {
        
        let heute: string = moment().format('YYYY-MM-DD');
        if(await this._db.wurdeAnAllDiesenTagenAbgerufen(0, heute))
        {
            let buecherVonDb: Array<BuchDTO> = await this._db.buecherVonHeute();
            return buecherVonDb.map(bd => new Buch(bd));
        } 
        else
        {
            try
            {
                const buecherVonService = await this._serviceClient.holeHeutigeBuecher();
                if(buecherVonService instanceof BuchError) return buecherVonService as BuchError;

                await this._db.speichereBuecher(buecherVonService.map(b => new BuchDbDTO(b)));
                let heute = new AbgerufenerTag(moment().format('YYYY-MM-DD'));
                await this._db.anDiesemTagBuecherAbgerufen(heute);
                let buecherVonDb = await this._db.buecherVonHeute();
                return buecherVonDb.map(bd => new Buch(bd));
            }
            catch(ex)
            {
                let buecherVonDb: Array<BuchDTO> = await this._db.buecherVonHeute();
                return buecherVonDb.map(bd => new Buch(bd));
            }            
        }
    }

    public async getBuch(buchId: string | null): Promise<Buch | BuchError | null>
    {
        if(!buchId) return null;

        let buch = await this._db.buch(buchId);
        if(!buch) 
        {
            let buchOrError = await this._serviceClient.holeBuch(buchId);
            if(buchOrError instanceof BuchError) return buchOrError as BuchError;
            else return new Buch(buchOrError as BuchDTO);
        }
        return new Buch(buch);
    }

    public async buecherVorhanden(): Promise<boolean>
    {
        let buecher = await this._db.buecher.count();
        return buecher > 0;
    }

    public async holeBuecherVonAutorenFuerHeute(): Promise<Array<Buch> | BuchError>
    {
        let autoren: Array<AutorDTO> = await this._db.autorenFilter();
        let heute: string = moment().format('YYYY-MM-DD');
        if(autoren && autoren.length > 0)
        {
            if(await this._db.wurdeAnAllDiesenTagenAbgerufen(0, heute))
            {
                let buecherVonDb: Array<BuchDTO> = await this._db.buecherVonHeute();
                let filteredBuecher = this.filterBuecherNachAutoren(buecherVonDb, autoren);
                return filteredBuecher.map(bd => new Buch(bd));
            }
            else
            {
                try
                {
                    const buecherHeute = await this._serviceClient.holeHeutigeBuecher();
                    if(buecherHeute instanceof BuchError) return buecherHeute as BuchError;

                    await this._db.speichereBuecher(buecherHeute.map(b => new BuchDbDTO(b)));
                    this._db.anDiesemTagBuecherAbgerufen(new AbgerufenerTag(heute));
                    let buecherVonDb = await this._db.buecherVonHeute();
                    let filteredBuecher = this.filterBuecherNachAutoren(buecherVonDb, autoren);
                    return filteredBuecher.map(bd => new Buch(bd));
                }
                catch(ex)
                {
                    let buecherVonDb: Array<BuchDTO> = await this._db.buecherVonHeute();                       
                    let filteredBuecher = this.filterBuecherNachAutoren(buecherVonDb, autoren);
                    return filteredBuecher.map(bd => new Buch(bd));
                }                
            }  
        }
        else
        {
            // todo: get autorenFilter from server (only when syncing user over different devices)
            return new Array<Buch>();
        }            
    }

    public async holeBuecherLetzteTage(tage: number): Promise<Array<Buch> | BuchError>
    {
        const heute = moment().format('YYYY-MM-DD');
        if(await this._db.wurdeAnAllDiesenTagenAbgerufen(tage, heute))
        {
            let buecherVonDb: Array<BuchDTO> = await this._db.buecherFuerZeitraum(tage, heute);
            return buecherVonDb.map(bd => new Buch(bd));
        }
        else
        {
            try
            {
                let buecherVonService = await this.holeBuecherVonServiceUndSpeicherSie(tage, heute);
                if(buecherVonService instanceof BuchError) return buecherVonService as BuchError;

                let buecherVonDb: Array<BuchDTO> = await this._db.buecherFuerZeitraum(tage, heute);
                return buecherVonDb.map(bd => new Buch(bd));  
            } catch(ex)
            {
                let error = new BuchError(false);
                return error; 
            }
        }
    }

    public async holeBuecherVonServiceUndSpeicherSie(tage: number, heute: string, withCancel = true): Promise<Array<BuchDTO> | BuchError>
    {
        const buecherVonService = 
            withCancel 
                ? await this._serviceClient.holeBuecherLetzteTage(tage) 
                : await this._serviceClient.holeBuecherLetzteTageInBackground(tage);
        
        if(buecherVonService instanceof BuchError) return buecherVonService as BuchError;

        await this._db.speichereBuecher(buecherVonService.map(b => new BuchDbDTO(b)));
        this._db.anDiesenTagenBuecherAbgerufen(tage, heute);
        return buecherVonService as Array<BuchDTO>;
    }

    public async holeBuecherVonAutorenFuerZeitraum(tage: number): Promise<Array<Buch> | BuchError>
    {
        const heute = moment().format('YYYY-MM-DD');
        let autoren: Array<AutorDTO> = await this._db.autorenFilter();
        if(autoren && autoren.length > 0) 
        {
            if(await this._db.wurdeAnAllDiesenTagenAbgerufen(tage, heute)) 
            {
                let buecherVonDb: Array<BuchDTO> = await this._db.buecherFuerZeitraum(tage, heute);
                let filteredBuecher = this.filterBuecherNachAutoren(buecherVonDb, autoren);
                return filteredBuecher.map(bd => new Buch(bd));
            }
            else
            {
                try
                {
                    let result = await this.holeBuecherVonServiceUndSpeicherSie(tage, heute);
                    if(result instanceof BuchError) return result as BuchError;

                    let buecherVonDb: Array<BuchDTO> = await this._db.buecherFuerZeitraum(tage, heute);
                    let filteredBuecher = this.filterBuecherNachAutoren(buecherVonDb, autoren);
                    return filteredBuecher.map(bd => new Buch(bd));
                }
                catch(ex) 
                {
                    let buecherVonDb: Array<BuchDTO> = await this._db.buecherFuerZeitraum(tage, heute);
                    let filteredBuecher = this.filterBuecherNachAutoren(buecherVonDb, autoren);
                    return filteredBuecher.map(bd => new Buch(bd));
                }                
            }
        }
        else {
            // todo: get autorenFilter from server (only when syncing user over different devices)
            return new Array<Buch>();
        }            
    }

    public filterBuecherNachAutoren(buecher: BuchDTO[], autoren: AutorDTO[])
    {
        if(!buecher) return new Array<BuchDTO>();        
        if(!autoren || autoren.length == 0) return buecher;

        let buecherVonAutoren: Array<BuchDTO> = new Array<BuchDTO>();
        for (const buch of buecher)
        {
            for (const buchAutor of buch.autoren)
            {
                let zwischenResult = false;
                for (const autor of autoren)
                {
                    zwischenResult = zwischenResult || autor.value.split(' ').every(a => buchAutor?.value?.toLowerCase().includes(a.toLowerCase()));
                }
                if (zwischenResult)
                    buecherVonAutoren.push(buch);
            }
        }
        return buecherVonAutoren;
    }

    public async holeAllBuecherVonAutor(autor: string): Promise<Array<Buch> | BuchError>
    {        
        if(await this._db.wurdeDieserAutorSchonAbgerufen(autor))
        {
            let buecherVonDb: Array<BuchDTO> = await this._db.alleBuecherVonAutor(autor);
            return buecherVonDb.map(bd => new Buch(bd));
        }
        else
        {
            try
            {
                const buecherVonService = await this._serviceClient.holeAlleBuecherVonAutor(autor);
                if(buecherVonService instanceof BuchError) return buecherVonService as BuchError;

                await this._db.speichereBuecher(buecherVonService.map(b => new BuchDbDTO(b)));
                await this._db.diesenAutorAbgerufen(autor);
                return buecherVonService.map(bd => new Buch(bd));
            } 
            catch(ex)
            {
                let buecherVonDb: Array<BuchDTO> = await this._db.alleBuecherVonAutor(autor);
                return buecherVonDb.map(bd => new Buch(bd));
            }
        }
    }

    public cancelOldRequest()
    {
        this._serviceClient.cancelPreviousHoleBuecher();
    }

}