import * as React from "react";
import "./merklistenOverlayComponent.css";
import "./shared.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeart } from '@fortawesome/free-solid-svg-icons';
import {Link} from "react-router-dom";


class MerklistenOverlay extends React.Component<{}>
{
    constructor(props: {}) 
    {
        super(props);
    }

    async componentDidMount() {}

    componentWillUnmount() {}

    render() 
    {        
        return <div className="merklisteOverlaynWrapper">
            <Link to={"/Merkliste"} className="merklistenButtonContent">
                <FontAwesomeIcon icon={faHeart} size="1x"/>
                Merkliste
            </Link>
        </div>;
    }

}
export default MerklistenOverlay;