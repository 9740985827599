import * as React from "react";
import "./ueberAcoyo.css";
import "./shared.css";
import BackArrow from "./backArrowSVGComponent";
import SchriftzugSVGComponent from "./SchriftzugSVGComponent";


class UeberAcoyoComponent extends React.Component<{}>
{
    constructor(props: {}) 
    {
        super(props);        
    }

    async componentDidMount() {}

    componentWillUnmount() {}
  
    render() 
    { 
        return <div>
                <div className="detailsHeader">
                    <button type="button" className="backButton" onClick={() => { this.goBack() }}><BackArrow/></button>
                    <h1>Über Acoyo</h1>
                </div>
                <div className="uberAcoyo">
                    <div className="imageWrapper"><SchriftzugSVGComponent/></div>
                    <p>Wir, die Acoyo Software GmbH, haben diese App entwickelt. Uns gibt es seit 2021 und unser Firmensitz ist Dresden. Unser generelles Ziel ist es, mehr Qualität in die digitale Welt zu bringen.
Die Buchmelder-App ist für uns ein Herzensprojekt. Die App steht kostenlos zur Verfügung. Lediglich über die Links zum Kaufen erhalten wir etwas Provision. Mit etwas Glück, können wir so unsere laufenden Kosten für die App decken.</p>

<p>Neben dieser App arbeiten wir sonst daran, für Kunden individuelle Lösungen mit ihnen gemeinsam zu entwickeln. Wir begleiten unsere Kunden von einer sorgfältigen Anforderungsanalyse, über die Implementierung mit zugehöriger Qualitätssicherungg, bis hin zur Wartung und Weiterentwickelung Ihrer Lösungen.</p>
<p>Mehr zu uns erfährst du hier: <a className="textHighlight" href="https://www.acoyo.de" target="_blank" rel="noopener noreferrer">www.acoyo.de</a></p>
                </div>
            </div>;               
    }

    private goBack(): void 
    {
        window.history.back();
    }
}
export default UeberAcoyoComponent;