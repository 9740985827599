import AxiosInstanceFactory from './AxiosInstanceFactory';
import {BuchDTO} from '../Datenmodell/BuchDTO';
import {AutorDTO} from '../Datenmodell/AutorDTO';
import {VorschlagsAutorDTO} from '../Datenmodell/VorschlagsAutorDTO';
import BuchError from '../Datenmodell/BuchError';
import Request from '../Datenmodell/Request';
import BuchmelderDb from './BuchmelderDb';
import { ExtendedPushSubscription } from '../Datenmodell/ExtendedPushSubscription';


export const baseUrl = "https://buchmelder-service-prod.azurewebsites.net"; 
export default class ServiceClient 
{
    private axiosInstanceFactory: AxiosInstanceFactory;
    private db: BuchmelderDb;
    public cancelationSource;

    public constructor(instanceFactory: AxiosInstanceFactory, db: BuchmelderDb) 
    {
        this.axiosInstanceFactory = instanceFactory;
        this.db = db;
        this.cancelationSource = this.axiosInstanceFactory.getCancallationSource();
    }

    public async registriereNutzerBeiService(): Promise<string> 
    {
        const response = await this.axiosInstanceFactory.getInstance().post<string>(`${baseUrl}/Nutzer`);
            return response.data;
    }

    public async existiertNutzerBereits(nutzerId: string): Promise<boolean>
    {
        const response = await this.axiosInstanceFactory.getInstance().get<boolean>(`${baseUrl}/Nutzer/${nutzerId}`);
            return response.data;
    }

    public async holeAutorenFilter(nutzerId: string): Promise<Array<AutorDTO>> 
    {
        const response = await this.axiosInstanceFactory.getInstance().get<Array<AutorDTO>>(`${baseUrl}/Nutzer/${nutzerId}/Filter/Autoren`);
            return response.data;
    }

    public async holeBuecherZuIds(buecherIds: Array<string>): Promise<Array<BuchDTO> | BuchError>
    {
        try
        { 
            const response = await this.axiosInstanceFactory.getInstance().post<Array<BuchDTO>>(`${baseUrl}/Buecher/FuerIds`, buecherIds, {
                cancelToken: this.cancelationSource.token
              });
            return response.data;
        }
        catch(ex)
        {
            if(this.couldNotSend(ex))
            {
                return new BuchError(true);
            }
            return new BuchError(false);
        }
    }

    public async holeAlleBuecherVonAutor(autor: string): Promise<Array<BuchDTO> | BuchError>
    {
        try
        { 
            const response = await this.axiosInstanceFactory.getInstance().get<Array<BuchDTO>>(`${baseUrl}/Buecher/AlleFuerAutor?autor=${autor}`);
            return response.data;
        }
        catch(ex)
        {
            if(this.couldNotSend(ex))
            {
                return new BuchError(true);
            }
            return new BuchError(false);
        }
    }

    public async holeMerkliste(nutzerId: string): Promise<Array<string>> 
    {
        const response = await this.axiosInstanceFactory.getInstance().get<Array<string>>(`${baseUrl}/Nutzer/${nutzerId}/Merkliste`);
            return response.data;
    }

    public async istAutorenFilterBereitsAktiv(nutzerId: string): Promise<boolean | BuchError> 
    {       
        let url = `${baseUrl}/Nutzer/${nutzerId}/Benachrichtigung/AutorenFilter`;
        const response = await this.axiosInstanceFactory.getInstance().get<boolean>(url);
        return response.data;       
    }

    public async holeHeutigeBuecher(): Promise<Array<BuchDTO> | BuchError> 
    {
        try
        {           
            const response = await this.axiosInstanceFactory.getInstance().get<Array<BuchDTO>>(`${baseUrl}/Buecher`, {
                cancelToken: this.cancelationSource.token
              });
            return response.data;
        }
        catch(ex)
        {
            if(this.couldNotSend(ex))
            {
                return new BuchError(true);
            }
            return new BuchError(false);
        }
    }

    public async holeBuecherLetzteTage(tage: number): Promise<Array<BuchDTO> | BuchError> 
    {
        try
        {
            const response = await this.axiosInstanceFactory.getInstance().get<Array<BuchDTO>>(`${baseUrl}/Buecher/LetzteTageVonHeute/${tage}`, {
                cancelToken: this.cancelationSource.token
              });
            return response.data;
        }
        catch(ex)
        {
            if(this.couldNotSend(ex))
            {
                return new BuchError(true);
            }
            return new BuchError(false);
        }
    }

    public async holeBuecherLetzteTageInBackground(tage: number): Promise<Array<BuchDTO> | BuchError> 
    {
        const response = await this.axiosInstanceFactory.getInstance().get<Array<BuchDTO>>(`${baseUrl}/Buecher/LetzteTageVonHeute/${tage}`);
        return response.data;       
    }

    public async holeBuch(buchId: string): Promise<BuchDTO | BuchError> 
    {
        try
        {
            const response = await this.axiosInstanceFactory.getInstance().get<BuchDTO>(`${baseUrl}/Buecher/${buchId}`);
                return response.data;
        }
        catch(ex)
        {
            if(this.couldNotSend(ex))
            {
                return new BuchError(true);
            }
            return new BuchError(false);
        }
    }

    public async holeAutorenVorschläge(): Promise<Array<VorschlagsAutorDTO>> 
    {
        const response = await this.axiosInstanceFactory.getInstance().get<Array<VorschlagsAutorDTO>>(`${baseUrl}/Collections/Autoren`);
            return response.data;
    }

    public async sendeNeuenAutorenFilter(nutzerId: string, autoren: Array<string>)
    {
        if(nutzerId)
        {
            let url = `${baseUrl}/Nutzer/${nutzerId}/Filter/Autoren`;
            try
            {
                await this.axiosInstanceFactory.getInstance().put(url, autoren);
            }
            catch(ex) 
            {
                if(this.couldNotSend(ex))
                {
                    let request = new Request(url, "put", JSON.stringify(autoren));
                    this.saveRequestForLater(request);
                }
            }
        }
        else
        {
            console.error("nutzerId war null.");
        }
    }

    public async sendeNeueMerkliste(nutzerId: string, merkliste: Array<string>)
    {
        if(nutzerId)
        {
            let url = `${baseUrl}/Nutzer/${nutzerId}/Merkliste`;
            try
            {
                await this.axiosInstanceFactory.getInstance().put(url, merkliste);
            }
            catch(ex) 
            {
                if(this.couldNotSend(ex))
                {
                    let request = new Request(url, "put", JSON.stringify(merkliste));
                    this.saveRequestForLater(request);
                }
            }
        }
        else
        {
            console.error("nutzerId war null.");
        }
    }

    public async subscribeToBenachrichtigungen(nutzerId: string, subscription: ExtendedPushSubscription) 
    {
        if(nutzerId)
        {
            let url = `${baseUrl}/Nutzer/${nutzerId}/Benachrichtigungen`;
            try
            {
                await this.axiosInstanceFactory.getInstance().put(url, subscription);
            }
            catch(ex) 
            {
                if(this.couldNotSend(ex))
                {
                    let request = new Request(url, "put", JSON.stringify(subscription));
                    this.saveRequestForLater(request);
                }
            }
        }
        else
        {
            console.error("nutzerId war null.");
        }
    }

    public async unsubscribeFromBenachrichtigungen(nutzerId: string) 
    {
        if(nutzerId)
        {            
            let url = `${baseUrl}/Nutzer/${nutzerId}/Benachrichtigung/Deaktivieren`;
            try
            {
                await this.axiosInstanceFactory.getInstance().put(url);
            }
            catch(ex) 
            {
                if(this.couldNotSend(ex))
                {
                    let request = new Request(url, "put", "");
                    this.saveRequestForLater(request);
                }
            }
        }        
        else
        {
            console.error("nutzerId war null.");
        }
    }

    public async activateAutorenFilterForBenachrichtigungen(nutzerId: string) 
    {
        if(nutzerId)
        {
            let url = `${baseUrl}/Nutzer/${nutzerId}/Benachrichtigung/AutorenFilter/Aktivieren`;
            try
            {
                await this.axiosInstanceFactory.getInstance().put(url);
            }
            catch(ex) 
            {
                if(this.couldNotSend(ex))
                {
                    let request = new Request(url, "put", "");
                    this.saveRequestForLater(request);
                }
            }
        }
        else
        {
            console.error("nutzerId war null.");
        }
    }

    public async deactivateAutorenFilterForBenachrichtigungen(nutzerId: string) 
    {
        if(nutzerId)
        {
            let url = `${baseUrl}/Nutzer/${nutzerId}/Benachrichtigung/AutorenFilter/Deaktivieren`;
            try
            {
                await this.axiosInstanceFactory.getInstance().put(url);
            }
            catch(ex) 
            {
                if(this.couldNotSend(ex))
                {
                    let request = new Request(url, "put", "");
                    this.saveRequestForLater(request);
                }
            }
        }
        else
        {
            console.error("nutzerId war null.");
        }
    }

    public async sendSavedRequest(request: Request)
    {
        if(request) {
            if(request.type == "put") 
            {
                await this.axiosInstanceFactory.getInstance().put(request.url, JSON.parse(request.body));
            }
            else 
            {
                await this.axiosInstanceFactory.getInstance().post(request.url, JSON.parse(request.body));
            }            
        }
    }

    public cancelPreviousHoleBuecher()
    {
        this.cancelationSource.cancel();
        this.cancelationSource = this.axiosInstanceFactory.getCancallationSource();
    }
    
    
    private async saveRequestForLater(request: Request)
    {
        await this.db.speicherRequest(request);
    }

    private couldNotSend(ex: any) {
        return ex && (ex.message == "Network Error" || ex.status == 503);
    }
}