import { AutorDTO } from "./AutorDTO";
import { BuchDTO } from "./BuchDTO";

export default class BuchDbDTO implements BuchDTO {
    id: string;
    titel: string;
    subTitel: string;
    beschreibung: string;
    autoren: Array<AutorDTO>;
    autorenForFilter: Array<string>;
    genres: string[];
    datumVeroeffentlichung: string;
    bigThumbnailUrl: string;
    smallThumbnailUrl: string;
    preis: number;
    waehrung: string;
    isEbook: boolean;
    verlag: string;
    sprache: string;
    seitenanzahl: number;
    isbn: string;
    buecherDeLink: string;
    istGemerkt?: boolean;

    public constructor(buch: BuchDTO) 
    {
            this.id= buch.id;
            this.titel = buch.titel;
            this.subTitel = buch.subTitel;
            this.beschreibung = buch.beschreibung;
            this.autoren = buch.autoren;
            this.autorenForFilter = buch.autoren.map(a => a.value);
            this.genres = buch.genres;
            this.datumVeroeffentlichung = buch.datumVeroeffentlichung;
            this.bigThumbnailUrl = buch.bigThumbnailUrl;
            this.smallThumbnailUrl = buch.smallThumbnailUrl;
            this.preis = buch.preis;
            this.waehrung = buch.waehrung;
            this.isEbook = buch.isEbook;
            this.verlag = buch.verlag;
            this.sprache = buch.sprache;
            this.seitenanzahl = buch.seitenanzahl;
            this.isbn = buch.isbn;
            this.buecherDeLink = buch.buecherDeLink;
            this.istGemerkt = buch.istGemerkt;
    }
}