import BuchmelderDb from './BuchmelderDb';
import ServiceClient from './ServiceClient';
import Nutzer from '../Datenmodell/Nutzer';
import GemerktesBuch from '../Datenmodell/GemerktesBuch';
import NutzerService from './NutzerService';
import Buch from '../Datenmodell/Buch';
import BuchError from '../Datenmodell/BuchError';
import BuchDbDTO from '../Datenmodell/BuchDbDTO';

export default class MerklistenService 
{
    private _serviceClient: ServiceClient;
    private _db: BuchmelderDb;
    private _nutzerService: NutzerService;

    public constructor(externalServiceClient: ServiceClient, buecherDb: BuchmelderDb, nService: NutzerService) 
    {
        this._serviceClient = externalServiceClient;
        this._db = buecherDb;
        this._nutzerService = nService;
    }

    public async getMerkliste(): Promise<Array<GemerktesBuch>> 
    {
        let buchIdsVonDb: Array<GemerktesBuch> = await this._db.gemerkteBuecher();
        if(!buchIdsVonDb || buchIdsVonDb.length == 0)
        {
            try {
                let nutzer: Nutzer | null = await this._nutzerService.getNutzer();
                if(nutzer) 
                {
                    let buchIdsvonService = await this._serviceClient.holeMerkliste(nutzer.id);
                    let buchIdsZumSpeichern = buchIdsvonService.map(bi => new GemerktesBuch(bi));
                    await this._db.speichereGemerkteBuecher(buchIdsZumSpeichern);
                    buchIdsVonDb = await this._db.gemerkteBuecher();
                }
                else
                {
                    return new Array<GemerktesBuch>();
                }
                
            } catch(ex) {
                console.error("Fehler beim Abrufen des Nutzers oder der Merkliste.");
            }
        }
        return buchIdsVonDb;
    }

    public async getMerklistenBuecher(): Promise<Array<Buch> | BuchError>
    {        
        let gemerkteBuecher = await this.getMerkliste();
        if(gemerkteBuecher)
        {
            try 
            {
                let ids = gemerkteBuecher.map(gm => gm.id);
                let buecherAusDb = await this._db.buecherFuerIds(ids);
                if(!buecherAusDb || buecherAusDb.length < gemerkteBuecher.length)
                {
                    let buecherVonService = await this._serviceClient.holeBuecherZuIds(gemerkteBuecher.map(gm => gm.id));
                    if(buecherVonService instanceof BuchError) return buecherVonService as BuchError;
    
                    await this._db.speichereBuecher(buecherVonService.map(b => new BuchDbDTO(b)));
                    buecherAusDb = await this._db.buecherFuerIds(ids);
                    return buecherAusDb.map(bd => new Buch(bd));
                }
                else {
                    return buecherAusDb.map(bd => new Buch(bd));
                }   
            }
            catch(ex)
            {
                return new BuchError(false);
            }
        }
        else {
            return new Array<Buch>();
        }   
    
    }

    public async istBuchAufMerkliste(buchId: string) : Promise<boolean>
    {
        let merkliste = await this.getMerkliste();
        return merkliste.some(b => b.id == buchId);
    }

    public async merkeBuch(buchId: string): Promise<void> 
    {
        let gBuch = new GemerktesBuch(buchId);
        await this._db.speichereGemerktesBuch(gBuch);
        await this._db.flaggeGemerktesBuch(gBuch);
        await this.sendMerklisteToServer();       
    }

    public async entmerkeBuch(buchId: string): Promise<void> 
    {
        if(buchId)
        {            
            await this._db.loescheGemerktesBuch(buchId);
            await this._db.entflaggeGemerktesBuch(buchId);
            await this.sendMerklisteToServer();
        }        
    }

    private async sendMerklisteToServer() 
    {
        try {
            let nutzer: Nutzer | null = await this._nutzerService.getNutzer();
            if(!nutzer) nutzer = await this._nutzerService.registriereNutzer();
            if (nutzer) {
                const neueMerkliste = await this._db.gemerkteBuecher();
                await this._serviceClient.sendeNeueMerkliste(nutzer.id, neueMerkliste.map(m => m.id));
            }

        } catch (ex) {
            console.error("Fehler beim Abrufen des Nutzers oder der Merkliste.");
        }
    }
}